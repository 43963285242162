import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";


const initialState = {
  languagesData: null,
  languagesLoading: false,
  languagesError: null,
  languageLevelsData: null,
  languageLevelsLoading: false,
  languageLevelsError: null,
  countriesData: null,
  countriesLoading: false,
  countriesError: null,
  citiesData: null,
  citiesLoading: false,
  citiesError: null,
};


export const languagesFunc = createAsyncThunk("parameters/languagesFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query User_parameters {
          user_parameters {
            languages {
              count
              data {
                _id
                name
                metadata {
                  code
                }
              }
            }
          }
        }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const languageLevelsFunc = createAsyncThunk("parameters/languageLevelsFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query LanguageLevels {
      user_parameters {
        languageLevels {
          count
          data {
            _id
            name
          }
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;

});

export const countriesFunc = createAsyncThunk("parameters/countriesFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `
    query Anonymous_countries {
      anonymous_countries {
        count
        data {
          _id
          name
          code
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;

});

export const citiesFunc = createAsyncThunk("parameters/citiesFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Anonymous_cities($countryId: ID!) {
      anonymous_cities(countryId: $countryId) {
        count
        data {
          _id
          name
        }
      }
    }`,
    variables: {
      countryId: values?.countryId
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;

});




const parameters = createSlice({
  name: "parameters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(languagesFunc.pending, (state) => {
      state.languagesLoading = true;
    });
    builder.addCase(languagesFunc.fulfilled, (state, action) => {
      state.languagesLoading = false;
      state.languagesData = action.payload?.user_parameters?.languages?.data;
      state.languagesError = action.payload.message;
    });
    builder.addCase(languagesFunc.rejected, (state, action) => {
      state.languagesLoading = false;
      state.languagesError = action.error.message;
    });

    builder.addCase(languageLevelsFunc.pending, (state) => {
      state.languageLevelsLoading = true;
    });
    builder.addCase(languageLevelsFunc.fulfilled, (state, action) => {
      state.languageLevelsLoading = false;
      state.languageLevelsData = action.payload?.user_parameters?.languageLevels?.data;
      state.languageLevelsError = action.payload.message;
    });
    builder.addCase(languageLevelsFunc.rejected, (state, action) => {
      state.languageLevelsLoading = false;
      state.languageLevelsError = action.error.message;
    });

    builder.addCase(countriesFunc.pending, (state) => {
      state.countriesLoading = true;
    });
    builder.addCase(countriesFunc.fulfilled, (state, action) => {
      state.countriesLoading = false;
      state.countriesData = action.payload?.anonymous_countries?.data;
      state.countriesError = action.payload.message;
    });
    builder.addCase(countriesFunc.rejected, (state, action) => {
      state.countriesLoading = false;
      state.countriesError = action.error.message;
    });

    builder.addCase(citiesFunc.pending, (state) => {
      state.citiesLoading = true;
    });
    builder.addCase(citiesFunc.fulfilled, (state, action) => {
      state.citiesLoading = false;
      state.citiesData = action.payload?.anonymous_cities?.data;
      state.citiesError = action.payload.message;
    });
    builder.addCase(citiesFunc.rejected, (state, action) => {
      state.citiesLoading = false;
      state.citiesError = action.error.message;
    });


  }
});


export default parameters.reducer;