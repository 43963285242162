import { Route } from 'react-router-dom'
import Dashboard from 'pages/client/Dashboard'
import Packages from 'pages/client/Packages'
import University from 'pages/client/University'
import Finance from 'pages/client/Finance'
import Ticket from 'pages/client/Ticket'
import Profile from 'pages/user/Profile'
import Settings from 'pages/user/Settings'
import Onboarding from 'pages/client/Onboarding'
import PaymentResult from 'pages/client/PaymentResult'
import Notepad from 'pages/user/Notepad'
import PaymentSuccess from 'pages/client/PaymentSuccess'

export const ClientDashboard = () => {

    return <>
        <Route path="/" element={<Dashboard />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/university" element={<University />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/payment/order/iyzicoCallback" element={<PaymentResult />} />
        <Route path="/payment/success" element={<PaymentSuccess />} />
        <Route path="/notepad" element={<Notepad />} />
        <Route path="*" element={<Dashboard />} />
    </>
}


export const ClientOnboarding = () => {
    return <>
        <Route path="/" element={<Onboarding />} />
        <Route path="*" element={<Onboarding />} />
    </>
}