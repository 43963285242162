import { useSelector } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'
import BizLayout from 'layouts/biz/BizLayout'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next';
import { MedalBox } from "components";

const info = [
    "",
]

const Dashboard = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { companiesOverviewData } = useSelector((state) => state.company);
    const { userBillingInfoCreditData: credits, withdrawnsData } = useSelector((state) => state.packages);

    return <BizLayout info={info} >
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t('Dashboard')}</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} mt={2}>
                    <Box
                        onClick={() => navigate('/company')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }}
                        display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Companies')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={3}>
                                <MedalBox
                                    medalType={t('Bronze')}
                                    bgColor="#FFEFE2"
                                    count={companiesOverviewData?.bronzeCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <MedalBox
                                    medalType={t('Silver')}
                                    bgColor="#F5F5F5"
                                    count={companiesOverviewData?.silverCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <MedalBox
                                    medalType={t('Gold')}
                                    bgColor="#fff1ba"
                                    count={companiesOverviewData?.goldCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <MedalBox
                                    medalType={t('VIP')}
                                    bgColor="#383e42"
                                    count={companiesOverviewData?.vipCount || 0}
                                    uppercase
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12} mt={2}>
                    <Box onClick={() => navigate('/finance')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Finance')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Package Sales')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Revenue')}</Typography>
                                    <Typography variant="h2">{'$ ' + (credits?.total || 0)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Withdrawn')}</Typography>
                                    <Typography variant="h2">{'$ ' + (withdrawnsData || 0)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Withdrawable')}</Typography>
                                    <Typography variant="h2">{'$ ' + (credits?.withdrawable || 0)}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12} mt={2}>
                    <Box onClick={() => navigate('/ticket')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Tickets')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Open')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Waiting')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Answered')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Closed')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    </BizLayout>
}

export default Dashboard