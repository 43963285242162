import { useTranslation } from 'react-i18next';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination } from '@mui/material'
import EditNoteIcon from '@mui/icons-material/EditNote';
import Label from 'src/components/label/label';


const UniversityListTable = ({ data, page, pageSize, dataCount, handleChangePage, handleClickOpenEdit }) => {

    const { t } = useTranslation();


    return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>{t("Name")}</TableCell>
                    <TableCell align="center">{t("University Name")}</TableCell>
                    <TableCell align="center">{t("City")} / {t("Country")}</TableCell>
                    <TableCell align="center"> {t("Standard / Pro / Special Sales")}</TableCell>
                    <TableCell align="center"> {t("Total Sales ($)")}</TableCell>
                    <TableCell align="center"> {t("Medal")}</TableCell>
                    <TableCell align="center"> {t("Note")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.length > 0 ?
                    data.map((item, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="th" scope="row">{page === 1 ? index + 1 : (page - 1) * pageSize + index + 1}</TableCell>
                            <TableCell component="th" scope="row">{item.universityAdmin?.displayName}</TableCell>
                            <TableCell align="center">{item.displayName}</TableCell>
                            <TableCell align="center">{item.city?.name} / {item.country?.name}</TableCell>
                            <TableCell align="center">
                                {item.universityPackageCounts?.standard?.total} / 
                                {item.universityPackageCounts?.pro?.total} / 
                                {item.universityPackageCounts?.special?.total}
                            </TableCell>
                            <TableCell align="center">$ {item.totalSalesTo}</TableCell>
                            <TableCell align="center">
                                {<Label variant="filled" color={item.medal}>
                                    {item.medal === 'bronze' ? 'Bronze' :
                                        item.medal === 'silver' ? 'Silver' :
                                            item.medal === 'gold' ? 'Gold' :
                                                item.medal === 'vip' ? 'VIP' : 'None'}
                                </Label>}
                            </TableCell>
                            <TableCell align="center">
                                <EditNoteIcon
                                    onClick={() => handleClickOpenEdit(item)}
                                    sx={{
                                        cursor: 'pointer',
                                        color: '#555',
                                        fontSize: 22,
                                        '&:hover': {
                                            color: '#000',
                                        },
                                    }}

                                />

                            </TableCell>
                        </TableRow>
                    )) :
                    <TableRow>
                        <TableCell colSpan={7} align="center">{t("No data found")}</TableCell>
                    </TableRow>
                }
            </TableBody>

        </Table>
        <Box display="flex" justifyContent="center" p={2}>
            {dataCount ? <Pagination
                count={Math.ceil(dataCount / pageSize)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                style={{ marginLeft: 10 }}
            /> : null}
        </Box>
    </TableContainer>
}

export default UniversityListTable
