import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BizLayout from "layouts/biz/BizLayout"
import {
    Box, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, CircularProgress, Pagination
} from '@mui/material'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Label from 'src/components/label/label';
import { useSnackbar } from 'notistack';
import { salesRep_registerFunc } from "context/auth/auth";
import { productsFunc } from "context/common/products";
import { PackageBox } from "components";
import { useTranslation } from 'react-i18next';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {
    createOrderStripeUserFunc, packagesOverviewFunc, sendPackageFunc,
    sendPackageHistoriesFunc, orderFunc
} from "context/common/packages";
import { userInternsByEmailsFunc } from "context/common/user";
import moment from "moment";
import Lottie from "react-lottie";
import PaymentSuccess from "assets/lottie/payment_success.json";

const info = [
    "Email listesinin check ve gönderilmesi için backend bekleniyor.",
    "Package listesi için backend bekleniyor"
]


const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const pageSize = 10;


const Packages = () => {

    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { accessToken, salesRep_registerLoading } = useSelector((state) => state.auth);
    const [openIyzico, setOpenIyzico] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const { userBillingInfoData, userBillingInfoLoading, sendPackageData } = useSelector(state => state.packages);
    const { packagesOverviewData } = useSelector(state => state.packages);
    const { sendPackageHistoriesData, sendPackageHistoriesCount } = useSelector(state => state.packages);
    const [orderId, setOrderId] = useState(null);
    const [iyzicoUrl, setIyzicoUrl] = useState(null);
    const [paymentResult, setPaymentResult] = useState(null);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [openSend, setOpenSend] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [emailInput, setEmailInput] = useState('');
    const [confirmedEmailList, setConfirmedEmailList] = useState([]);
    const [unconfirmedEmailList, setUnconfirmedEmailList] = useState([]);
    const [confirmedUserIdList, setConfirmedUserIdList] = useState([]);
    const [openBuy, setOpenBuy] = useState(false);
    const [standardA, setStandardA] = useState(null);
    const [standardB, setStandardB] = useState(null);
    const [standardC, setStandardC] = useState(null);
    const [proA, setProA] = useState(null);
    const [proB, setProB] = useState(null);
    const [proC, setProC] = useState(null);
    const [specialA, setSpecialA] = useState(null);
    const [specialB, setSpecialB] = useState(null);
    const [specialC, setSpecialC] = useState(null);
    let i = 0;

    const standartFeatures = [
        { title: t("Company Selection"), strikeThrough: true },
        { title: t("Certificate of Participation") },
        { title: t("Reference Letter"), strikeThrough: true },
        { title: t("1 Country") },
        { title: t("General Support") },
        { title: t("Orientation") },
        { title: t("Mentoring") },
        { title: t("Micro Enterprises") },
    ]

    const proFeatures = [
        { title: t("Company Selection") },
        { title: t("Certificate of Participation") },
        { title: t("Reference Letter") },
        { title: t("2 Countries") },
        { title: t("Dedicated Support") },
        { title: t("Orientation") },
        { title: t("Mentoring") },
        { title: t("Small Enterprises") },
    ]

    const specialFeatures = [
        { title: t("Company Selection"), strikeThrough: true },
        { title: t("Certificate of Participation") },
        { title: t("Reference Letter") },
        { title: t("3 Countries") },
        { title: t("Dedicated Support") },
        { title: t("Orientation") },
        { title: t("Mentoring") },
        { title: t("Medium Enterprises") },
    ]

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleClickOpenSend = ({ packageType }) => {
        setOpenSend(true);
        setSelectedPackage(packageType);
    };

    const handleClickOpenBuy = ({ packageType }) => {
        setOpenBuy(true);
        setSelectedPackage(packageType);
    };

    const handleClose = () => {
        setOpen(false);
        formik.resetForm();
    };

    const handleCloseSend = () => {
        setOpenSend(false);
        setSelectedPackage(null);
    };

    const handleCloseBuy = () => {
        setOpenBuy(false);
        setSelectedPackage(null);
    };


    const handleEmailInputChange = (e) => {
        setEmailInput(e.target.value);
    };

    const handleInternSend = async () => {
        const packageType = selectedPackage === "Standard Package" ? 'standard' :
            selectedPackage === "Pro Package" ? "pro" :
                selectedPackage === "Special Package" ? "special" : "";

        await dispatch(sendPackageFunc({ accessToken: accessToken, internIds: confirmedUserIdList, packageType: packageType, amountPerIntern: 1 })).then((res) => {
            if (res?.payload?.client_sendPackages?.success) {
                enqueueSnackbar('Package sent successfully', { variant: 'success' });
                dispatch(sendPackageHistoriesFunc({ accessToken: accessToken, page: 1, pageSize: pageSize }));
                handleCloseSend();
            } else {
                enqueueSnackbar('Something went wrong', { variant: 'error' });
            }
        }).catch((err) => {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        });
    };


    const handleEmailInputCheck = () => {
        const emails = emailInput.split(',').map((email) => email.trim()).filter((email) => email).concat(confirmedEmailList).concat(unconfirmedEmailList);


        console.log(emails)
        dispatch(userInternsByEmailsFunc({ accessToken: accessToken, emails: emails })).then((res) => {
            setConfirmedEmailList(emails.map((email) => res?.payload?.user_internsByEmails?.find((intern) => intern?.email === email)?.email).filter((email) => email));
            setConfirmedUserIdList(emails.map((email) => res?.payload?.user_internsByEmails?.find((intern) => intern?.email === email)?.internId).filter((email) => email));
            setUnconfirmedEmailList(emails.filter((email) => !res?.payload?.user_internsByEmails?.find((intern) => intern?.email === email)));
        })

        setEmailInput('');
    };


    const handleRemoveEmailUncofirmed = (index) => {
        setUnconfirmedEmailList(unconfirmedEmailList.filter((email, i) => i !== index));
    };

    const handleRemoveEmailConfirmed = (index) => {
        setConfirmedEmailList(confirmedEmailList.filter((email, i) => i !== index));
    };

    const formik = useFormik({
        initialValues: {
            amount: '',
            email: '',
            role: 'b2bClient',
            accessToken: accessToken
        },
        validationSchema,
        onSubmit: (values) => {
            dispatch(salesRep_registerFunc(values)).then((res) => {
                if (res?.payload.isSuccess) {
                    enqueueSnackbar('Finance added successfully', { variant: 'success' });
                    handleClose();
                } else if (res?.payload?.response?.data?.errors?.[0]?.msg === 'Email already in use') {
                    enqueueSnackbar("Email already in use!", { variant: 'error' });
                } else {
                    enqueueSnackbar('Something went wrong', { variant: 'error' });
                }
            }).catch((err) => {
                enqueueSnackbar('Something went wrong', { variant: 'error' });
            });
        },
    });


    const handleClickBuy = (value) => {
        console.log("buy" + selectedPackage + " " + value)
        dispatch(createOrderStripeUserFunc({
            accessToken: accessToken,
            productId:
                ((selectedPackage === "Standard Package") && value === 0) ? standardA?._id
                    : (selectedPackage === "Standard Package" && value === 1) ? standardB?._id
                        : (selectedPackage === "Standard Package" && value === 2) ? standardC?._id
                            : (selectedPackage === "Pro Package" && value === 0) ? proA?._id
                                : (selectedPackage === "Pro Package" && value === 1) ? proB?._id
                                    : (selectedPackage === "Pro Package" && value === 2) ? proC?._id
                                        : (selectedPackage === "Special Package" && value === 0) ? specialA?._id
                                            : (selectedPackage === "Special Package" && value === 1) ? specialB?._id
                                                : (selectedPackage === "Special Package" && value === 2) ? specialC?._id
                                                    : null
        })).then((res) => {
            if (res?.payload?.data?.user_createOrderForUserWithStripeCheckoutFormPayment?.paymentResult?.url) {
                // localStorage.setItem('orderId', res?.payload?.data?.user_createOrderForUserWithIyzicoCheckoutFormPayment?.order?._id)
                window.location.href = res?.payload?.data?.user_createOrderForUserWithStripeCheckoutFormPayment?.paymentResult?.url;
            } else {
                setOpenBuy(false)
                enqueueSnackbar(<div>
                    You need to add billing information!
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => navigate('/settings?tab=billingInfo')} >
                        Go to Edit
                    </Button>
                </div >, { variant: 'warning', autoHideDuration: 5000 });
            }

        }).catch((err) => {
            console.log(err, 'xxx')
            return err;
        })
    }

    const handleIframeLoad = (event) => {
        i = i + 1;

        if (i === 2) {
            const iframe = event.target;
            console.log('iframe', iframe)

            setOpenIyzico(false)
            setOpenResult(true)
            i = 0;
        }
    };

    useEffect(() => {
        dispatch(productsFunc({ accessToken: accessToken })).then((res) => {
            console.log(res?.payload)
            setStandardA(res?.payload?.user_products?.data?.filter(item => item.name === "Standard - A")?.[0])
            setStandardB(res?.payload?.user_products?.data?.filter(item => item.name === "Standard - B")?.[0])
            setStandardC(res?.payload?.user_products?.data?.filter(item => item.name === "Standard - C")?.[0])
            setProA(res?.payload?.user_products?.data?.filter(item => item.name === "Pro - A")?.[0])
            setProB(res?.payload?.user_products?.data?.filter(item => item.name === "Pro - B")?.[0])
            setProC(res?.payload?.user_products?.data?.filter(item => item.name === "Pro - C")?.[0])
            setSpecialA(res?.payload?.user_products?.data?.filter(item => item.name === "Special - A")?.[0])
            setSpecialB(res?.payload?.user_products?.data?.filter(item => item.name === "Special - B")?.[0])
            setSpecialC(res?.payload?.user_products?.data?.filter(item => item.name === "Special - C")?.[0])
        })
    }, [dispatch, accessToken])

    useEffect(() => {
        sendPackageData && dispatch(packagesOverviewFunc({ accessToken: accessToken }))
    }, [dispatch, accessToken, sendPackageData])

    useEffect(() => {
        accessToken && dispatch(sendPackageHistoriesFunc({ accessToken: accessToken, page: page, pageSize: pageSize }))
    }, [dispatch, accessToken, page])

    useEffect(() => {
        const fetchData = async () => {
            if (openResult) {
                const delay = ms => new Promise(res => setTimeout(res, ms));
                await delay(3000);
                const res = await dispatch(orderFunc({ accessToken, orderId }));
                const orderStatus = res?.payload?.order?.orderStatus;
                if (orderStatus === 'completed') {
                    setPaymentResult("completed");
                } else {
                    await delay(4000);
                    const res = await dispatch(orderFunc({ accessToken, orderId }));
                    const orderStatus = res?.payload?.order?.orderStatus;
                    if (orderStatus === 'completed') {
                        setPaymentResult("completed");
                    } else {
                        await delay(5000);
                        const res = await dispatch(orderFunc({ accessToken, orderId }));
                        const orderStatus = res?.payload?.order?.orderStatus;
                        if (orderStatus === 'completed') {
                            setPaymentResult("completed");
                        } else {
                            setPaymentResult("waiting");
                        }
                    }
                }
            }
        };

        fetchData();

        return () => setPaymentResult(false);

    }, [orderId, dispatch, accessToken, openResult]);

    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("Packages")}</Typography>
            <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={6} md={4}>
                    <PackageBox
                        medalType={"Standard Package"}
                        letterSpace={1}
                        bgColor="#FFEFE2"
                        features={standartFeatures}
                        count={t('Usable') + " : " + (packagesOverviewData ? packagesOverviewData?.standard?.usable : 0)}
                        count2={t("Total") + " : " + (packagesOverviewData ? packagesOverviewData?.standard?.total : 0)}
                        handleClickOpen={handleClickOpenSend}
                        handleClickOpen2={handleClickOpenBuy}
                        handleClose={handleCloseSend}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <PackageBox
                        medalType={"Pro Package"}
                        letterSpace={1}
                        bgColor="#F5F5F5"
                        features={proFeatures}
                        count={t('Usable') + " : " + (packagesOverviewData ? packagesOverviewData?.pro?.usable : 0)}
                        count2={t("Total") + " : " + (packagesOverviewData ? packagesOverviewData?.pro?.total : 0)}
                        handleClickOpen={handleClickOpenSend}
                        handleClickOpen2={handleClickOpenBuy}
                        handleClose={handleCloseSend}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <PackageBox
                        medalType={"Special Package"}
                        bgColor="#fff1ba"
                        letterSpace={1}
                        features={specialFeatures}
                        count={t('Usable') + " : " + (packagesOverviewData ? packagesOverviewData?.special?.usable : 0)}
                        count2={t("Total") + " : " + (packagesOverviewData ? packagesOverviewData?.special?.total : 0)}
                        handleClickOpen={handleClickOpenSend}
                        handleClickOpen2={handleClickOpenBuy}
                        handleClose={handleCloseSend}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={3} alignItems="center">
                <Grid item xs={6} >
                    <Typography variant="h5">{t("Package List")}</Typography>
                </Grid>

            </Grid>

            <Grid container spacing={0} mt={3} sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
            }} component={Paper} >
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">{t("Name Surname")}</TableCell>
                                <TableCell align="center">{t("Sending Date")}</TableCell>
                                <TableCell align="center">{t("Standard / Pro / Special Package")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sendPackageHistoriesData?.length > 0 ?
                                sendPackageHistoriesData?.map((item, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}      >
                                        <TableCell component="th" scope="row">{page === 1 ? index + 1 : (page - 1) * pageSize + index + 1}</TableCell>
                                        <TableCell align="left">
                                            <img src={item.user?.displayImageUrl} alt="profile" style={{ width: 30, height: 30, borderRadius: 50, border: "2px solid #999", marginRight: 10 }} />
                                            {item.user?.displayName}</TableCell>
                                        <TableCell align="center">{moment(item.createdAt).format("DD.MM.YYYY - HH:mm")}</TableCell>
                                        <TableCell align="center">
                                            {<Label variant="filled" sx={{ width: 100 }}
                                                color={item.packageType === 'standard' ? 'bronze' :
                                                    item.packageType === 'pro' ? 'silver' :
                                                        item.packageType === 'special' ? 'gold' : ''}>
                                                {item.packageType === 'standard' ? 'Standard' :
                                                    item.packageType === 'pro' ? 'Pro' :
                                                        item.packageType === 'special' ? 'Special' : ''}
                                            </Label>}
                                        </TableCell>

                                    </TableRow>
                                )) :
                                <TableRow>
                                    <TableCell colSpan={6} align="center">{t('No data found')}</TableCell>
                                </TableRow>
                            }
                        </TableBody>

                    </Table>
                    <Box display="flex" justifyContent="center" p={2}>
                        <Pagination
                            count={Math.ceil(sendPackageHistoriesCount / pageSize)}
                            page={page}
                            onChange={handleChange}
                            color="primary"
                            style={{ marginLeft: 10 }}
                        />
                    </Box>
                </TableContainer>
            </Grid>
            <Box height={40} />
        </Box>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={formik.handleSubmit} style={{ padding: 10 }}>
                <DialogTitle id="form-dialog-title">{t("Send Package")}</DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        {t("Send package to intern.")}<br /><br />
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        inputProps={{ maxLength: 35 }}
                        label="Name"
                        type="text"
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />

                    <TextField
                        margin="dense"
                        id="surname"
                        name="surname"
                        inputProps={{ maxLength: 35 }}
                        label="Surname"
                        type="text"
                        fullWidth
                        value={formik.values.surname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.surname && Boolean(formik.errors.surname)}
                        helperText={formik.touched.surname && formik.errors.surname}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        {t("Cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary"
                        disabled={salesRep_registerLoading}
                    >
                        {salesRep_registerLoading ? <CircularProgress size={20} /> : t("Send")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

        <Dialog open={openSend} onClose={handleCloseSend} aria-labelledby="form-dialog-title">
            <form onSubmit={formik.handleSubmit} style={{ padding: { xs: 1, md: 20 } }}>
                <DialogTitle id="form-dialog-title">{t("Send Package")}</DialogTitle>
                <DialogContent sx={{ minWidth: { xs: '100%', sm: 500 } }} >
                    <DialogContentText >
                        {t("Send package to intern.")} < br /> <br />
                    </DialogContentText>
                    <TextField
                        label="E-postalar (Virgülle Ayırın)"
                        fullWidth
                        value={emailInput}
                        onChange={handleEmailInputChange}
                    />
                    <Box mt={3} >
                        <strong>Email List:</strong>
                        <ul style={{ paddingLeft: 0, marginLeft: 0, marginTop: 1, listStyleType: 'none' }}>
                            {confirmedEmailList.map((email, index) => (
                                <li key={index} style={{ alignItems: 'center', display: 'flex' }}>
                                    {email}{' '}
                                    <span
                                        onClick={() => handleRemoveEmailConfirmed(index)}
                                        style={{ cursor: 'pointer', color: '#FD4956' }}
                                    >
                                        <HighlightOffIcon fontSize="small" sx={{ ml: 1, mt: 1 }} />
                                    </span>
                                </li>
                            ))}
                            {unconfirmedEmailList.map((email, index) => (
                                <li key={index} style={{ alignItems: 'center', display: 'flex', color: '#FD4956' }}>
                                    {email}{' '}
                                    <span
                                        onClick={() => handleRemoveEmailUncofirmed(index)}
                                        style={{ cursor: 'pointer', color: '#FD4956' }}
                                    >
                                        <HighlightOffIcon fontSize="small" sx={{ ml: 1, mt: 1 }} />
                                    </span>

                                    <span style={{ color: '#777', marginLeft: 10 }}> (Not Found)</span>
                                </li>
                            ))}
                        </ul>
                        {unconfirmedEmailList?.length > 0 && <span style={{ color: '#FD4956', paddingTop: "30px" }}> {t('Please check the email list before sending.')}</span>}

                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSend} color="primary" variant="outlined">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={(confirmedEmailList?.length > 0 && unconfirmedEmailList?.length === 0 && emailInput === '') ? handleInternSend :
                        ((confirmedEmailList?.length === 0 && unconfirmedEmailList?.length === 0) || emailInput !== '') ? handleEmailInputCheck : handleInternSend}
                        variant="contained" color="primary"
                        disabled={unconfirmedEmailList?.length > 0 && emailInput === ''}
                    >
                        {(confirmedEmailList?.length > 0 && unconfirmedEmailList?.length === 0 && emailInput === '') ? t("Send") :
                            ((confirmedEmailList?.length === 0 && unconfirmedEmailList?.length === 0) || emailInput !== '') ? t("Check") : t("Send")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog >

        <Dialog open={openBuy} onClose={handleCloseBuy} aria-labelledby="form-dialog-title">

            <DialogTitle id="form-dialog-title">{t("Buy")} {t(selectedPackage)}</DialogTitle>

            <DialogContent sx={{ minWidth: { xs: '100%', md: 430 }, py: 5 }} >

                <DialogContentText >
                    {t("Buy package to intern.")} < br /> <br />
                </DialogContentText>

                <Box display="flex" sx={{ width: '100%' }} justifyContent={'space-between'} >
                    <Button variant="outlined" color="primary" onClick={() => handleClickBuy(0)} sx={{ width: 120 }}>
                        <Box display="flex" alignItems="center" flexDirection={"column"}>
                            <Typography variant="p" color="primary" >{t("for")}</Typography>
                            <Typography variant="h4" color="primary" >
                                {selectedPackage === "Standard Package" && standardA?.amount}
                                {selectedPackage === "Pro Package" && proA?.amount}
                                {selectedPackage === "Special Package" && specialA?.amount}
                            </Typography>
                            <Typography variant="p" color="primary" >{t("intern")}</Typography>
                            <Box height={20} />
                            <Typography variant="h5" color="primary" bgcolor={"secondary"}>
                                {selectedPackage === "Standard Package" && `$${standardA?.price}`}
                                {selectedPackage === "Pro Package" && `$${proA?.price}`}
                                {selectedPackage === "Special Package" && `$${specialA?.price}`}
                            </Typography>

                            <Box height={20} />
                            <Typography fontSize="10px" variant="p" color="primary" bgcolor={"secondary"}>
                                <span style={{ display: 'inline', textDecoration: 'line-through' }}>
                                    {selectedPackage === "Standard Package" && `$${standardA?.listUnitPrice}`}
                                    {selectedPackage === "Pro Package" && `$${proA?.listUnitPrice}`}
                                    {selectedPackage === "Special Package" && `$${specialA?.listUnitPrice}`}
                                </span>
                                {selectedPackage === "Standard Package" && ` $${standardA?.unitPrice} per intern`}
                                {selectedPackage === "Pro Package" && ` $${proA?.unitPrice} per intern`}
                                {selectedPackage === "Special Package" && ` $${specialA?.unitPrice} per intern`}
                            </Typography>
                        </Box>
                    </Button>

                    <Button variant="outlined" color="primary" onClick={() => handleClickBuy(1)} sx={{ width: 120, my: -1 }}>
                        <AutoAwesomeIcon color="secondary" sx={{ position: 'absolute', top: -0, right: -0, fontSize: 35 }} />
                        <Box display="flex" alignItems="center" flexDirection={"column"}>
                            <Typography variant="p" color="primary" >{t("for")}</Typography>
                            <Typography variant="h4" color="primary" >
                                {selectedPackage === "Standard Package" && standardB?.amount}
                                {selectedPackage === "Pro Package" && proB?.amount}
                                {selectedPackage === "Special Package" && specialB?.amount}
                            </Typography>
                            <Typography variant="p" color="primary" >{t("intern")}</Typography>
                            <Box height={20} />
                            <Typography variant="h5" color="primary" bgcolor={"secondary"}>
                                {selectedPackage === "Standard Package" && `$${standardB?.price}`}
                                {selectedPackage === "Pro Package" && `$${proB?.price}`}
                                {selectedPackage === "Special Package" && `$${specialB?.price}`}
                            </Typography>

                            <Box height={20} />
                            <Typography fontSize="10px" variant="p" color="primary" bgcolor={"secondary"}>
                                <span style={{ display: 'inline', textDecoration: 'line-through' }}>
                                    {selectedPackage === "Standard Package" && `$${standardB?.listUnitPrice}`}
                                    {selectedPackage === "Pro Package" && `$${proB?.listUnitPrice}`}
                                    {selectedPackage === "Special Package" && `$${specialB?.listUnitPrice}`}
                                </span>
                                {selectedPackage === "Standard Package" && ` $${standardB?.unitPrice} per intern`}
                                {selectedPackage === "Pro Package" && ` $${proB?.unitPrice} per intern`}
                                {selectedPackage === "Special Package" && ` $${specialB?.unitPrice} per intern`}
                            </Typography>
                        </Box>
                    </Button>

                    <Button variant="outlined" color="primary" onClick={() => handleClickBuy(2)} sx={{ width: 110 }}>
                        <Box display="flex" alignItems="center" flexDirection={"column"}>
                            <Typography variant="p" color="primary" >{t("for")}</Typography>
                            <Typography variant="h4" color="primary" >
                                {selectedPackage === "Standard Package" && standardC?.amount}
                                {selectedPackage === "Pro Package" && proC?.amount}
                                {selectedPackage === "Special Package" && specialC?.amount}
                            </Typography>
                            <Typography variant="p" color="primary" >{t("intern")}</Typography>
                            <Box height={20} />
                            <Typography variant="h5" color="primary" bgcolor={"secondary"}>
                                {selectedPackage === "Standard Package" && `$${standardC?.price}`}
                                {selectedPackage === "Pro Package" && `$${proC?.price}`}
                                {selectedPackage === "Special Package" && `$${specialC?.price}`}
                            </Typography>

                            <Box height={20} />
                            <Typography fontSize="10px" variant="p" color="primary" bgcolor={"secondary"}>
                                <span style={{ display: 'inline', textDecoration: 'line-through' }}>
                                    {selectedPackage === "Standard Package" && `$${standardC?.listUnitPrice}`}
                                    {selectedPackage === "Pro Package" && `$${proC?.listUnitPrice}`}
                                    {selectedPackage === "Special Package" && `$${specialC?.listUnitPrice}`}
                                </span>
                                {selectedPackage === "Standard Package" && ` $${standardC?.unitPrice} per intern`}
                                {selectedPackage === "Pro Package" && ` $${proC?.unitPrice} per intern`}
                                {selectedPackage === "Special Package" && ` $${specialC?.unitPrice} per intern`}
                            </Typography>
                        </Box>
                    </Button>
                </Box>
            </DialogContent>

            <DialogActions>

                <Button onClick={handleCloseBuy} color="primary" variant="outlined">
                    {t("Cancel")}
                </Button>

            </DialogActions>

        </Dialog >

        <Dialog open={openIyzico} onClose={() => setOpenIyzico(false)} aria-labelledby="form-dialog-title" maxWidth="md" >
            <DialogTitle>
                {t('Payment with Iyzico')}
            </DialogTitle>
            <DialogContent style={{ width: '500px', height: '500px', overflowY: 'hidden', padding: 0 }}>
                <div style={{ width: '100%', height: '100%', }}>
                    <iframe
                        allowFullScreen={true}
                        referrerPolicy='origin'
                        // dangerouslySetInnerHTML={{ __html: iyzicoContent }}
                        allow='autoplay *; fullscreen *; encrypted-media *; picture-in-picture *; geolocation *; microphone *; camera *; midi *'
                        width={'100%'}
                        height={'100%'}
                        style={{ width: '100%', height: '100%', border: 'none' }}
                        title="iyzico"
                        src={iyzicoUrl}
                        // referrerPolicy="origin"
                        onLoad={handleIframeLoad}
                    />
                </div>
            </DialogContent>
        </Dialog>


        <Dialog open={openResult} onClose={() => {
            setOpenResult(false)
            setOpenBuy(false)
            dispatch(packagesOverviewFunc({ accessToken }))
        }} aria-labelledby="form-dialog-title"  >
            <DialogTitle>
                Payment Result
            </DialogTitle>
            <DialogContent style={{
                width: { xs: '100%', sm: '500px' }, height: '300px', padding: { xs: 5, sm: 0 }, display: "flex", flexDirection: "column",
                justifyContent: "flex-start", alignItems: "center"
            }}>
                {(paymentResult !== "completed" && paymentResult !== "waiting") && <>
                    <Typography variant="h6" textAlign={"center"} mb={5}>Please wait...</Typography>
                    <CircularProgress />
                </>}
                {paymentResult === "completed" && <>
                    <Typography variant="h6" textAlign={"center"} mb={5}>Payment is completed successfully.</Typography>
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: PaymentSuccess,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            }
                        }}
                        height={'60%'}
                        width={'60%'}
                    />
                </>}
                {paymentResult === "waiting" && <>
                    <Typography variant="h6" textAlign={"center"} mb={5}>The payment process took longer than usual. Please check your account by refreshing the page..</Typography>
                </>}
                {/* {paymentResult === 'aborted' && paymentResult && <Lottie
                        animationData={PaymentFailed}
                        style={{ width: '60%', height: '60%' }}
                        autoplay={true}
                    />} */}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={() => { setOpenResult(false) }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    </BizLayout >
}

export default Packages