import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    clientListData: null,
    clientListCount: null,
    clientListLoading: false,
    clientListError: null,
    clientsOverviewData: null,
    clientsOverviewLoading: false,
    clientsOverviewError: null,
};


export const clientListFunc = createAsyncThunk("client/clientListFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query SalesRep_clients($pagination: Pagination) {
            salesRep_clients(pagination: $pagination) {
              count
              data {
                _id
                displayName
                clientCompanyName
                clientPackageCounts {
                  standard {
                    usable
                  }
                  pro {
                    usable
                  }
                  special {
                    usable
                  }
                }
                totalSalesTo
        
                medal
              }
            }
          }`,
        variables: {
            "pagination": {
                "page": values.page,
                "pageSize": values.pageSize
            }
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


export const clientsOverviewFunc = createAsyncThunk("client/clientsOverviewFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query ClientsOverview {
            salesRep_dashboardOverviews {
              clientsOverview {
                bronzeCount
                silverCount
                goldCount
                vipCount
              }
            }
          }`,
        variables: {}
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});




const client = createSlice({
    name: "client",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(clientListFunc.pending, (state) => {
            state.clientListLoading = true;
        }
        );
        builder.addCase(clientListFunc.fulfilled, (state, action) => {
            state.clientListLoading = false;
            state.clientListData = action.payload?.salesRep_clients?.data;
            state.clientListCount = action.payload?.salesRep_clients?.count;
            state.clientListError = action.payload.message;
        }
        );
        builder.addCase(clientListFunc.rejected, (state, action) => {
            state.clientListLoading = false;
            state.clientListError = action.error.message;
        }
        );
        builder.addCase(clientsOverviewFunc.pending, (state) => {
            state.clientsOverviewLoading = true;
        }
        );
        builder.addCase(clientsOverviewFunc.fulfilled, (state, action) => {
            state.clientsOverviewLoading = false;
            state.clientsOverviewData = action.payload?.salesRep_dashboardOverviews?.clientsOverview;
            state.clientsOverviewError = action.payload.message;
        }
        );
        builder.addCase(clientsOverviewFunc.rejected, (state, action) => {
            state.clientsOverviewLoading = false;
            state.clientsOverviewError = action.error.message;
        }
        );
    }
});


export default client.reducer;