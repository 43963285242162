import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    orderListData: null,
    orderListLoading: false,
    orderListError: null,
};


export const orderListFunc = createAsyncThunk("finance/orderListFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Orders($filter: FilterOrderInput, $pagination: Pagination) {
            orders(filter: $filter, pagination: $pagination) {
              count
              data {
                price {
                  netTotal
                  totalDiscount
                }
                orderStatus
                product {
                  type
                  amount
                  listUnitPrice
                    unitPrice
                    price
                    discount
                    purchaseMethod
                }
                payment {
                  paymentMethod
                }
                _id
                addresses {
                    billingAddress {
                      fullName
                    }
                  }
              }
            }
          }`,
        variables: {
            "filter": {
                "userId": values.userId,
                "orderStatus": "completed"
            },
            "pagination": {
                "page": values.page,
                "pageSize": values.pageSize
            }
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});




const finance = createSlice({
    name: "finance",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(orderListFunc.pending, (state) => {
            state.orderListLoading = true;
        }
        );
        builder.addCase(orderListFunc.fulfilled, (state, action) => {
            state.orderListLoading = false;
            state.orderListData = action.payload?.orders?.data;
            state.orderListError = action.payload.message;
        }
        );
        builder.addCase(orderListFunc.rejected, (state, action) => {
            state.orderListLoading = false;
            state.orderListError = action.error.message;
        }
        );
    }
});


export default finance.reducer;