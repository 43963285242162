import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";


const initialState = {
    createNoteToUserData: null,
    createNoteToUserLoading: false,
    createNoteToUserError: null,
    createNoteToSelfData: null,
    createNoteToSelfLoading: false,
    createNoteToSelfError: null,
    getUserNotesToSelfData: null,
    getUserNotesToSelfLoading: false,
    getUserNotesToSelfError: null,
    updateNoteData: null,
    updateNoteLoading: false,
    updateNoteError: null,
    getUserNotesToUserData: null,
    getUserNotesToUserLoading: false,
    getUserNotesToUserError: null,
};

export const createNoteToUserFunc = createAsyncThunk("notes/createNoteToUserFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `mutation Mutation($noteInput: NoteToUserInput!) {
            user_createNoteToUser(noteInput: $noteInput) {
              _id
            }
          }`,
        variables: {
            "noteInput": {
                "targetId": values?.targetId,
                "title": values?.targetId,
                "content": values?.content
            }
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const createNoteToSelfFunc = createAsyncThunk("notes/createNoteToSelfFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `mutation User_createNoteToSelf($noteInput: NoteToSelfInput!) {
            user_createNoteToSelf(noteInput: $noteInput) {
              _id
            }
          }`,
        variables: {
            "noteInput": {
                "title": values?.title,
                "content": values?.content
            }
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const getUserNotesToSelfFunc = createAsyncThunk("notes/getUserNotesToSelfFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Data {
            user_notesToSelf {
              data {
                _id
                title
                content
                targetType
              }
            }
          }`,
        variables: {}
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const updateNoteFunc = createAsyncThunk("notes/updateNoteFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `mutation User_updateNote($noteId: ID!, $noteUpdateInput: UpdateNoteInput!) {
            user_updateNote(noteId: $noteId, noteUpdateInput: $noteUpdateInput) {
              _id
            }
          }`,
        variables: {
            "noteId": values?.noteId,
            "noteUpdateInput": {
                "content": values?.content
            }
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;

});

export const getUserNotesToUserFunc = createAsyncThunk("notes/getUserNotesToUserFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query User_notesToUser($filter: FilterNoteInput) {
            user_notesToUser(filter: $filter) {
              data {
                _id
                content
              }
            }
          }`,
        variables: {
            "filter": {
                "targetId": values.targetId
            }
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;

});







const notes = createSlice({
    name: "notes",
    initialState,
    reducers: {
        setAutoInfo: (state, action) => {
            state.autoInfo = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createNoteToUserFunc.pending, (state) => {
            state.createNoteToUserLoading = true;
        });
        builder.addCase(createNoteToUserFunc.fulfilled, (state, action) => {
            state.createNoteToUserLoading = false;
            state.createNoteToUserData = action.payload.user_createNoteToUser;
            state.createNoteToUserError = action.payload.message;
        });
        builder.addCase(createNoteToUserFunc.rejected, (state, action) => {
            state.createNoteToUserLoading = false;
            state.createNoteToUserError = action.error.message;
        });

        builder.addCase(createNoteToSelfFunc.pending, (state) => {
            state.createNoteToSelfLoading = true;
        });
        builder.addCase(createNoteToSelfFunc.fulfilled, (state, action) => {
            state.createNoteToSelfLoading = false;
            state.createNoteToSelfData = action.payload.user_createNoteToSelf;
            state.createNoteToSelfError = action.payload.message;
        });
        builder.addCase(createNoteToSelfFunc.rejected, (state, action) => {
            state.createNoteToSelfLoading = false;
            state.createNoteToSelfError = action.error.message;
        });

        builder.addCase(getUserNotesToSelfFunc.pending, (state) => {
            state.getUserNotesToSelfLoading = true;
        });
        builder.addCase(getUserNotesToSelfFunc.fulfilled, (state, action) => {
            state.getUserNotesToSelfLoading = false;
            state.getUserNotesToSelfData = action.payload.user_notesToSelf?.data;
            state.getUserNotesToSelfError = action.payload.message;
        });
        builder.addCase(getUserNotesToSelfFunc.rejected, (state, action) => {
            state.getUserNotesToSelfLoading = false;
            state.getUserNotesToSelfError = action.error.message;
        });

        builder.addCase(updateNoteFunc.pending, (state) => {
            state.updateNoteLoading = true;
        });
        builder.addCase(updateNoteFunc.fulfilled, (state, action) => {
            state.updateNoteLoading = false;
            state.updateNoteData = action.payload.user_updateNote;
            state.updateNoteError = action.payload.message;
        });
        builder.addCase(updateNoteFunc.rejected, (state, action) => {
            state.updateNoteLoading = false;
            state.updateNoteError = action.error.message;
        });

        builder.addCase(getUserNotesToUserFunc.pending, (state) => {
            state.getUserNotesToUserLoading = true;
        });
        builder.addCase(getUserNotesToUserFunc.fulfilled, (state, action) => {
            state.getUserNotesToUserLoading = false;
            state.getUserNotesToUserData = action.payload.user_notesToUser?.data?.[0]
            state.getUserNotesToUserError = action.payload.message;
        });
        builder.addCase(getUserNotesToUserFunc.rejected, (state, action) => {
            state.getUserNotesToUserLoading = false;
            state.getUserNotesToUserError = action.error.message;
        });



    }
});

export default notes.reducer;