import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import BizLayout from "layouts/biz/BizLayout"
import { Box, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MyTipTap } from "components/TextEditors"
import { createNoteToSelfFunc, getUserNotesToSelfFunc, updateNoteFunc } from "context/common/notes"
import { useSnackbar } from "notistack"

const info = [
    "Bitti"
]

const Notepad = () => {


    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { accessToken } = useSelector((state) => state.auth)
    const { getUserNotesToSelfData } = useSelector((state) => state.notes)
    const [value, setValue] = useState(null)
    const [changed, setChanged] = useState(false)
    const [editorKey, setEditorKey] = useState(0);


    const handleSave = () => {
        getUserNotesToSelfData?.length > 0 ?
            dispatch(updateNoteFunc({
                title: "Notepad",
                content: value,
                accessToken: accessToken,
                noteId: getUserNotesToSelfData?.[0]?._id
            })).then((res) => {
                console.log(res)
                enqueueSnackbar(t("Note updated successfully"), { variant: 'success', });
                dispatch(getUserNotesToSelfFunc({ accessToken: accessToken }));
            })
            : dispatch(createNoteToSelfFunc({
                title: "Notepad",
                content: value,
                accessToken: accessToken
            })).then((res) => {
                console.log(res)
                enqueueSnackbar(t("Note created successfully"), { variant: 'success', });
                dispatch(getUserNotesToSelfFunc({ accessToken: accessToken }));
            });
    }

    const handleFocus = () => {
        setChanged(true)
    }

    useEffect(() => {
        getUserNotesToSelfData && setValue(getUserNotesToSelfData?.[0]?.content)
        setEditorKey((prevKey) => prevKey + 1);
    }, [getUserNotesToSelfData])


    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("Notepad")}</Typography>
            <MyTipTap
                value={value}
                key={editorKey}
                handleFocus={handleFocus}
                setValue={(e) => {
                    setValue(e)
                }}
            />
            <Button
                onClick={handleSave}
                disabled={value ? changed ? false : true : true}

                variant="contained"
                color="primary" size="large" sx={{ mt: 2, px: 5 }}>{t("Save")}</Button>
        </Box>

    </BizLayout>
}

export default Notepad