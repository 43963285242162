
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Grid, Box, Typography, Link, Divider, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Button } from '@mui/material';
import AuthLayout from 'layouts/auth/AuthLayout'
import { enqueueSnackbar } from 'notistack'

import { AuthHead } from 'components'
import { useLocation, useNavigate } from 'react-router-dom';
import { requestResetPasswordFunc } from 'context/auth/auth';





const CheckYourMail = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSendPopup, setIsSendPopup] = useState(false)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const email = urlSearchParams.get("email");


    const handleResendCode = () => {
        dispatch(requestResetPasswordFunc({ email: email })).then((res) => {
            console.log(res)
            if (res?.payload?.isSuccess) {
                enqueueSnackbar('Code sent successfully!', { variant: 'success' })
            }
            if (res.payload?.response?.status === 400) {
                enqueueSnackbar(res.payload?.response?.data?.errors?.[0]?.msg === "RegistrationAlreadyConfirmed" ? "Registration already confirmed!" : 'Code sent failed!', { variant: 'warning' })
            }
        }).catch((err) => {
            enqueueSnackbar('Code sent failed!', { variant: 'error' })
        }
        )
    }






    return <AuthLayout>
        <Grid item xs={11} sm={9} md={6} lg={5} xl={5} justifyContent="center" alignItems="center" flexDirection={"column"}>


            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                <AuthHead title="Check Your Email" description={`We have sent a link to your email address so you can reset your password.`} />
            </Box>

            <Dialog open={isSendPopup} onClose={() => setIsSendPopup(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Resend Code</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to resend code?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setIsSendPopup(false)} color="primary">
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={() => {
                        setIsSendPopup(false)
                        handleResendCode()
                    }} color="primary">
                        Resend
                    </Button>
                </DialogActions>
            </Dialog>


            <Divider sx={{ width: "100%", marginTop: "30px" }} />
            {/* Didn't receive the code? Request a new one. Already have an account? Log in */}
            <Box justifyContent="center" alignItems={'center'} width="100%" mt={"35px"} mb={"30px"}>
                <Box display={"flex"} justifyContent="center" alignItems={'center'} width="100%">
                    <Typography variant="h7" color="textSecondary" sx={{ textAlign: "center" }}>
                        Didn’t receive the code? <Link style={{ textDecoration: 'none' }} component="span" onClick={() => setIsSendPopup(true)} color="info.main" variant="h7" sx={{ cursor: "pointer" }}>
                            Request a new one
                        </Link>
                    </Typography>
                </Box>
                <Box mt={2}  display={"flex"} justifyContent="center" alignItems={'center'} width="100%">
                    <Typography variant="h7" color="textSecondary" sx={{ textAlign: "center" }}>
                        {" "}Already have an account? <Link style={{ textDecoration: 'none' }} component="span" onClick={() => navigate("/signin")} color="info.main" variant="h7" sx={{ cursor: "pointer" }}>
                            Log in
                        </Link>
                    </Typography>
                </Box>
            </Box>

        </Grid >


    </AuthLayout >
}



export default CheckYourMail