import {
    Box,
    Grow,
    Paper,
    Popper,
    Divider,
    MenuItem,
    MenuList,
    Typography,
    ToggleButton,
    ClickAwayListener,
    ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react'


// tip-tap
import Image from '@tiptap/extension-image'
import StarterKit from '@tiptap/starter-kit'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import { EditorProvider, useCurrentEditor, useEditor } from '@tiptap/react'

// icons
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import LayersClearOutlinedIcon from '@mui/icons-material/LayersClearOutlined';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import SafetyDividerOutlinedIcon from '@mui/icons-material/SafetyDividerOutlined';
import StrikethroughSOutlinedIcon from '@mui/icons-material/StrikethroughSOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';


import './styles.scss'

console.error = () => { };

const MyStyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({

    display: 'flex',
    flexWrap: 'wrap',
    border: '0px',

    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,


        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: "16px",
            border: "1px solid #eee",
            paddingLeft: '6px',
            paddingRight: '6px',
        },
        '&:first-of-type': {
            borderRadius: "16px",
            border: "1px solid #eee"
        },
    },
}));

const MenuBar = () => {

    const { editor } = useCurrentEditor()

    const [header, setHeader] = React.useState('text');



    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };



    const [openImageTooltip, setOpenImageTooltip] = React.useState(false);
    const handleTooltipClose = () => {
        setOpenImageTooltip(false);
    };
    const handleTooltipOpen = () => {
        setOpenImageTooltip(true);
    };




    return (
        <div className='tiptap_header'>

            <MyStyledToggleButtonGroup
                size="small"
                exclusive
                aria-label="text alignment"
            >

                <Box
                    onClick={handleToggle}
                    ref={anchorRef}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '120px',
                        p: 1,
                        // backgroundColor: 'red',
                    }}
                    className={editor.isActive('heading') ? 'is-active' : ''}
                >
                    <Typography
                        // onClick={handleClick}
                        sx={{
                            color: '#555',
                            textTransform: 'capitalize',

                        }}
                    >
                        {header}
                    </Typography>


                    <ArrowDropDownIcon

                        sx={{
                            color: '#555',
                            textTransform: 'none',
                            cursor: 'pointer'
                        }}
                    />

                </Box>

                <Popper
                    sx={{
                        zIndex: 1,
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>

                                        <MenuItem
                                            onClick={() => {
                                                setHeader("Header 1")
                                                editor.chain().focus().toggleHeading({ level: 1 }).run()
                                            }}
                                            className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                                        >
                                            Header 1
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                setHeader("Header 2")
                                                editor.chain().focus().toggleHeading({ level: 2 }).run()
                                            }}
                                            className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                                        >
                                            Header 2
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                setHeader("Header 3")
                                                editor.chain().focus().toggleHeading({ level: 3 }).run()
                                            }}
                                            className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
                                        >
                                            Header 3
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                setHeader("Header 4")
                                                editor.chain().focus().toggleHeading({ level: 4 }).run()
                                            }}
                                            className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
                                        >
                                            Header 4
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                setHeader("Header 5")
                                                editor.chain().focus().toggleHeading({ level: 5 }).run()
                                            }}
                                            className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
                                        >
                                            Header 5
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                setHeader("Header 6")
                                                editor.chain().focus().toggleHeading({ level: 6 }).run()
                                            }}
                                            className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
                                        >
                                            Header 6
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                setHeader("text")
                                                editor.chain().focus().setParagraph().run()
                                            }}
                                            className={editor.isActive('paragraph') ? 'is-active' : ''}
                                        >
                                            Text
                                        </MenuItem>

                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>


                <ToggleButton value="bold" aria-label="bold" className={editor.isActive('bold') ? 'is-active' : ''}>
                    <FormatBoldIcon
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        disabled={
                            !editor.can()
                                .chain()
                                .focus()
                                .toggleBold()
                                .run()
                        }

                    />
                </ToggleButton>

                <ToggleButton value="italic" aria-label="italic" className={editor.isActive('italic') ? 'is-active' : ''}>
                    <FormatItalicIcon
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        disabled={
                            !editor.can()
                                .chain()
                                .focus()
                                .toggleItalic()
                                .run()
                        }

                    />
                </ToggleButton>

                <ToggleButton value="underline" aria-label="underline" className={editor.isActive('underline') ? 'is-active' : ''}>
                    <FormatUnderlinedIcon
                        onClick={() => editor.chain().focus().toggleUnderline().run()}
                        disabled={
                            !editor.can()
                                .chain()
                                .focus()
                                .toggleUnderline()
                                .run()
                        }

                    />
                </ToggleButton>

                <ToggleButton value="strike" aria-label="strike" className={editor.isActive('strike') ? 'is-active' : ''}>
                    <StrikethroughSOutlinedIcon
                        onClick={() => editor.chain().focus().toggleStrike().run()}
                        disabled={
                            !editor.can()
                                .chain()
                                .focus()
                                .toggleStrike()
                                .run()
                        }
                    />
                </ToggleButton>

                <Divider style={{ marginLeft: 20, marginRight: 20, width: 3, padding: 0, backgroundColor: '#ddd' }} />

                <ToggleButton value="numberedList" aria-label="numberedList" className={editor.isActive('orderedList') ? 'is-active' : ''}>
                    <FormatListNumberedOutlinedIcon onClick={() => editor.chain().focus().toggleOrderedList().run()} />
                </ToggleButton>

                <ToggleButton value="bulletedList" aria-label="bulletedList" className={editor.isActive('bulletList') ? 'is-active' : ''}>
                    <FormatListBulletedOutlinedIcon onClick={() => editor.chain().focus().toggleBulletList().run()} />
                </ToggleButton>

                <ToggleButton value="quote" aria-label="quote" className={editor.isActive('blockquote') ? 'is-active' : ''}>
                    <FormatQuoteOutlinedIcon onClick={() => editor.chain().focus().toggleBlockquote().run()} />
                </ToggleButton>

                <ToggleButton value="code" aria-label="code" className={editor.isActive('codeBlock') ? 'is-active' : ''}>
                    <CodeOutlinedIcon onClick={() => editor.chain().focus().toggleCodeBlock().run()} />
                </ToggleButton>
                <ToggleButton value="dvider" aria-label="dvider" className={editor.isActive('horizontal-rule') ? 'is-active' : ''}>
                    <SafetyDividerOutlinedIcon onClick={() => editor.chain().focus().setHorizontalRule().run()} />
                </ToggleButton>

                <Divider style={{ marginLeft: 20, marginRight: 20, width: 3, padding: 0, backgroundColor: '#ddd' }} />


                <ToggleButton
                    value="undo"
                    aria-label="undo"
                    onClick={() => editor.chain().focus().undo().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .undo()
                            .run()
                    }
                >
                    <UndoOutlinedIcon />
                </ToggleButton>

                <ToggleButton
                    value="redo"
                    aria-label="redo"
                    onClick={() => editor.chain().focus().redo().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .redo()
                            .run()
                    }
                >
                    <RedoOutlinedIcon />
                </ToggleButton>

                <ToggleButton value="clear" aria-label="clear">
                    <LayersClearOutlinedIcon onClick={() => editor.chain().focus().unsetAllMarks().run()} />
                </ToggleButton>


            </MyStyledToggleButtonGroup>

        </div>
    )
}


const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
    }),
    Image.configure({
        inline: true,
        allowBase64: true,
        HTMLAttributes: {
            class: 'my-custom-class',
        },
    }),
    Underline.configure({
        HTMLAttributes: {
            class: 'my-custom-class',
        },
    }),
]


export const MyTipTap = ({ value, setValue, handleFocus }) => {



    const editor = useEditor({

        extensions: [
            Color.configure({ types: [TextStyle.name, ListItem.name] }),
            TextStyle.configure({ types: [ListItem.name] }),
            StarterKit.configure({
                bulletList: {
                    keepMarks: true,
                    keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
                },
                orderedList: {
                    keepMarks: true,
                    keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
                },
            }),
            Image.configure({
                inline: true,
                allowBase64: true,
                HTMLAttributes: {
                    class: 'my-custom-class',
                },
            }),
            Underline.configure({
                HTMLAttributes: {
                    class: 'my-custom-class',
                },
            }),
        ],

        content: value,

        onUpdate: ({ editor }) => {
            setValue(editor.getHTML())
        }

    })


    useEffect(() => {
        if (editor) {
            editor?.commands?.setContent(value)
        }
    }, [editor, value])


    return (
        <div className='tiptap_editor'>

             <EditorProvider
                slotBefore={<MenuBar editor={editor} />}
                extensions={extensions}
                content={value}
                onSelectionUpdate={
                    () => {
                        handleFocus()
                    }
                }
                onUpdate={({ editor }) => {
                    setValue(editor.getHTML())
                }}
            />

        </div>
    )
}