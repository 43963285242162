import axios from "axios";


const uploadImage = (values) => {

    console.log(values, '!!!!!')

    let data = values.data;

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: values.presignedUploadUrl,
        headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': 'image/jpeg'
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
            console.log(error);
        });


}

export default uploadImage





