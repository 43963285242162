import { MotionContainer, varSlide } from 'src/components/animate';
import { motion } from 'framer-motion';
import { Typography } from "@mui/material";

export const AnimatedTitle = ({ title }) => {
    return <MotionContainer component={motion.h1} sx={{ typography: 'h1', display: 'flex', overflow: 'hidden' }}    >
        {title?.split('').map((character, index) => (
            <motion.span key={index} variants={varSlide().inUp}>
                <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>
                    {character === ' ' ? '\u00A0' : character} </Typography>
            </motion.span>
        ))}
    </MotionContainer>
}
