import { Box, Typography } from '@mui/material'
import { useLocation } from 'react-router'
import { InternifLogoOnly, VerifyEmailIcon } from 'assets'

const AuthHead = ({ title, description }) => {

    const path = useLocation().pathname.split('/')[1];

    return <>
        <Box sx={{ width: '64px', height: '64px' }}>
            {path === 'create-new-password' ? <img src={VerifyEmailIcon} alt='verify-email-icon' />
            : path ===  'forgot-password' ? <img src={VerifyEmailIcon} alt='verify-email-icon' />
            : <InternifLogoOnly />}
        </Box>
        <Typography variant="h1" textAlign={'center'}>
            {title}
        </Typography>
        <Typography variant='h6' fontWeight={'bold'} color="primary.light" textAlign="center">
            {description}
        </Typography>
    </>
}

export default AuthHead