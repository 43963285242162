import { useEffect, useState } from 'react';
import { Grid } from '@mui/material'
import { useMediaQuery } from '@mui/material';
import BackToHome from 'components/Buttons/BackToHome';
import { AuthImage1, BackgroundImage, AuthImage4, AuthImage2, AuthBiz, CreateNewPassword } from 'assets'
import AuthSlider from 'components/Sections/AuthSlider';
import PageOpening from 'components/Motions/PageOpening';
import { useLocation } from 'react-router-dom';

const AuthLayout = ({ children }) => {

  const path = useLocation().pathname.split('/')[1];

  const [pageHeight, setPageHeight] = useState(window.innerHeight);

  const downXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const updatePageHeight = () => {
    setPageHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageHeight);

    return () => {
      window.removeEventListener('resize', updatePageHeight);
    };
  }, []);

  return <PageOpening>
    <Grid container sx={{ height: pageHeight }}>
      {!downXs &&
        <Grid item xs={12} sm={5}
          sx={{
            backgroundImage: `url(${path === 'signup' ? AuthImage4 :
                path === 'check-your-email' ? AuthImage2 :
                  path === 'create-new-password' ? CreateNewPassword :
                    path === 'forgot-password' ? CreateNewPassword :
                      AuthBiz})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center',
          }}
        >
          <AuthSlider
            style={{
              height: { xs: pageHeight, md: pageHeight, lg: pageHeight },
              maxHeight: { xs: pageHeight, md: pageHeight, lg: pageHeight },
              display: { xs: 'none', md: 'block' },
              position: 'relative',
              zIndex: 1,
              pageHeight: pageHeight,
            }}
          />
        </Grid>}
      <Grid item xs={12} sm={7} display="flex" >
        <BackToHome />
        <Grid py={5} container 
          justifyContent="center" alignItems="center">

          {children}
        </Grid>
      </Grid>

    </Grid>
  </PageOpening>
}

export default AuthLayout