import { Route } from 'react-router-dom'
import Dashboard from 'pages/salesRep/Dashboard'
import Client from 'pages/salesRep/Client'
import University from 'pages/salesRep/University'
import Finance from 'pages/salesRep/Finance'
import Ticket from 'pages/salesRep/Ticket'
import Profile from 'pages/user/Profile'
import Settings from 'pages/user/Settings'
import Onboarding from 'pages/salesRep/Onboarding'
import Notepad from 'pages/user/Notepad'


export const SalesRepDashboard = () => {

    return <>
        <Route path="/" element={<Dashboard />} />
        <Route path="/client" element={<Client />} />
        <Route path="/university" element={<University />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/notepad" element={<Notepad />} />
        <Route path="*" element={<Dashboard />} />
    </>
}


export const SalesRepOnboarding = () => {
    return <>
        <Route path="/" element={<Onboarding />} />
        <Route path="*" element={<Onboarding />} />
    </>
}