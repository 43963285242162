import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BizLayout from "layouts/biz/BizLayout"
import {
    Box, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, CircularProgress, Pagination, InputAdornment
} from '@mui/material'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Label from 'src/components/label/label';
import { useSnackbar } from 'notistack';
import { salesRep_registerFunc } from "context/auth/auth";
import { MedalBox } from "components";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import HistoryIcon from '@mui/icons-material/History';
import { useTranslation } from 'react-i18next';
import { createCreditWithdrawalRequestFunc, withdrawalsFunc, user_packageHistoryOverviewFunc } from "context/common/packages";
import moment from "moment";
import { internListFunc } from "context/internRep/intern";

const info = ["-"
]

const validationSchema = Yup.object({
    amount: Yup.number().min(1000, "Minimum $1000").required("Required"),
});
const pageSize = 10;

const Finance = () => {

    const [open, setOpen] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken, salesRep_registerLoading } = useSelector((state) => state.auth);
    const { internListData, internListCount } = useSelector((state) => state.intern);
    const { withdrawalsData,withdrawnsData, userBillingInfoCreditData: credits, createCreditWithdrawalRequestData, 
        userPackageHistoryOverviewData: packageOverview
     } = useSelector((state) => state.packages);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const pendingWithdrawals = withdrawalsData?.filter((item) => item.status === 'pending');
    const notPendingWithdrawals = withdrawalsData?.filter((item) => item.status !== 'pending');

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenHistory = () => {
        setOpenHistory(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenHistory(false);
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            amount: '',
            accessToken: accessToken
        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values)
            dispatch(createCreditWithdrawalRequestFunc(values)).then((response) => {
                console.log(response)
                if (response?.payload?.data?.user_createCreditWithdrawalRequest?._id) {
                    enqueueSnackbar(t("Withdrawal request is created."), { variant: 'success' });
                    handleClose();
                } else {
                    enqueueSnackbar(response?.error?.message ?? 'Check your balance.', { variant: 'error' });
                }
            });
        },
    });

    useEffect(() => {
        dispatch(internListFunc({ accessToken: accessToken, page: page, pageSize: pageSize }));
        dispatch(withdrawalsFunc({ accessToken: accessToken }));
    }, [dispatch, accessToken, page, createCreditWithdrawalRequestData]);


    return <BizLayout info={info} >
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("Finance")}</Typography>
            <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6} md={4}>
                    <MedalBox
                        medalType={t("Standard Package")}
                        letterSpace={1}
                        bgColor="#FFEFE2"
                        count={packageOverview?.standard || 0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MedalBox
                        medalType={t("Pro Package")}
                        letterSpace={1}
                        bgColor="#F5F5F5"
                        count={packageOverview?.packageOverview || 0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MedalBox
                        medalType={t("Special Package")}
                        bgColor="#fff1ba"
                        letterSpace={1}
                        count={packageOverview?.special || 0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MedalBox
                        medalType={t("Revenue")}
                        bgColor="#e3e3e3"
                        letterSpace={1}
                        count={'$ ' + (credits ? credits?.total : 0)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MedalBox
                        medalType={t("Withdrawn")}
                        bgColor="#e3e3e3"
                        letterSpace={1}
                        count={'$ ' + (withdrawnsData || 0)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MedalBox
                        medalType={t("Withdrawable")}
                        bgColor="#e3e3e3"
                        letterSpace={1}
                        count={'$ ' + (credits?.withdrawable || 0)}
                    />
                </Grid>
            </Grid>

            {pendingWithdrawals?.length > 0 && <>
                <Grid container spacing={2} mt={3} alignItems="center">
                    <Grid item xs={6} >
                        <Typography variant="h5">{t("Pending Withdrawals")}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={0} mt={3} sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: 1,
                    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
                }} component={Paper} >
                    <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{t("Request Date")}</TableCell>
                                    <TableCell align="center">{t("Amount")} ($)</TableCell>
                                    <TableCell align="center">{t("Status")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pendingWithdrawals?.map((item, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}      >
                                        <TableCell >{page === 1 ? index + 1 : (page - 1) * pageSize + index + 1}</TableCell>
                                        <TableCell align="left">{moment(item.createdAt).format("DD MMM YYYY")}</TableCell>
                                        <TableCell align="center">$ {item.paymentAmount?.amount}</TableCell>
                                        <TableCell align="center">
                                            {<Label
                                                color={item.status === 'pending' ? 'warning' :
                                                    item.status === 'evaluating' ? 'info' :
                                                        item.status === 'rejected' ? 'error' :
                                                            item.status === 'paymentpending' ? 'info' :
                                                                item.status === 'paymentFailed' ? 'error' :
                                                                    item.status === 'paymentSuccessful' ? 'success' : 'info'} >
                                                {item.status === 'pending' ? 'Pending' :
                                                    item.status === 'evaluating' ? 'Evaluating' :
                                                        item.status === 'rejected' ? 'Rejected' :
                                                            item.status === 'paymentpending' ? 'Payment Pending' :
                                                                item.status === 'paymentFailed' ? 'Payment Failed' :
                                                                    item.status === 'paymentSuccessful' ? 'Payment Successful' : ' '}
                                            </Label>}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </>}

            <Grid container spacing={2} mt={3} alignItems="center">
                <Grid item xs={6} >
                    <Typography variant="h5">{t("Finance List")}</Typography>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent={"end"}>
                    <Button onClick={handleClickOpenHistory} style={{ marginRight: 20 }}
                        variant="outlined" color="primary" size="large"> <HistoryIcon color="white"
                            fontSize="medium" style={{ marginRight: 10 }} />
                        {t("History")}
                    </Button>
                    <Button onClick={handleClickOpen}
                        variant="contained" color="primary" size="large"> <CurrencyExchangeIcon color="white"
                            fontSize="medium" style={{ marginRight: 10 }} />
                        {t("Withdraw")}
                    </Button>

                </Grid>
            </Grid>

            <Grid container spacing={0} mt={3} sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
            }} component={Paper} >
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>{t("Type")}</TableCell>
                                <TableCell align="center">{t("Name Surname")}</TableCell>
                                <TableCell align="center">{t("Sales")} ($)</TableCell>
                                <TableCell align="center">{t("Medal")}</TableCell>
                                <TableCell align="center">{t("Revenue")} ($)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {internListData?.length > 0 ?
                                internListData?.map((item, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}      >
                                        <TableCell component="th" scope="row">{page === 1 ? index + 1 : (page - 1) * pageSize + index + 1}</TableCell>
                                        <TableCell align="center">{item.clientCompanyName}</TableCell>
                                        <TableCell align="center">{item.displayName}</TableCell>
                                        <TableCell align="center">$ {item.clientPackageCounts?.standard?.usable}</TableCell>
                                        <TableCell align="center">
                                            {<Label variant="filled" color={item.medal}>
                                                {item.medal === 'bronze' ? 'Bronze' :
                                                    item.medal === 'silver' ? 'Silver' :
                                                        item.medal === 'gold' ? 'Gold' :
                                                            item.medal === 'vip' ? 'VIP' : 'None'}
                                            </Label>}
                                        </TableCell>
                                        <TableCell align="center">$ {item.totalSalesTo}</TableCell>

                                    </TableRow>
                                )) :
                                <TableRow>
                                    <TableCell colSpan={6} align="center">{t('No data found')}</TableCell>
                                </TableRow>
                            }
                        </TableBody>

                    </Table>
                    <Box display="flex" justifyContent="center" p={2}>
                        <Pagination
                            count={Math.ceil(internListCount / pageSize)}
                            page={page}
                            onChange={handleChange}
                            color="primary"
                            style={{ marginLeft: 10 }}
                        />
                    </Box>
                </TableContainer>
            </Grid>
            <Box height={40} />
        </Box>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={formik.handleSubmit} style={{ padding: 10 }}>
                <DialogTitle id="form-dialog-title">{t("Withdraw Money")}</DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        {t("Enter the amount you want to withdraw.")}<br /><br />
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="amount"
                        name="amount"
                        label={t("Amount") + " ($)"}
                        type="text"
                        fullWidth
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                        helperText={formik.touched.amount && formik.errors.amount}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        {t("Cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary"
                        onClick={formik.handleSubmit}
                    >
                        {t("Withdraw")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>


        <Dialog open={openHistory} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t("History")}</DialogTitle>
            <DialogContent sx={{ mb: 2 }}>
                <DialogContentText>
                    {t("You can view your past withdrawals.")}<br /><br />
                </DialogContentText>
                <Grid container spacing={0} mt={3} sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: 1,
                    mb: 2,
                    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
                }} component={Paper} >
                    <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 450 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{t("Request Date")}</TableCell>
                                    <TableCell align="center">{t("Amount")} ($)</TableCell>
                                    <TableCell align="center">{t("Status")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {notPendingWithdrawals?.length > 0 ?
                                    notPendingWithdrawals?.map((item, index) => (
                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}      >
                                            <TableCell >{page === 1 ? index + 1 : (page - 1) * pageSize + index + 1}</TableCell>
                                            <TableCell align="left">{moment(item.createdAt).format("DD MMM YYYY hh:mm a")}</TableCell>
                                            <TableCell align="center">$ {item.paymentAmount?.amount}</TableCell>
                                            <TableCell align="center">
                                                {<Label
                                                    color={item.status === 'pending' ? 'warning' :
                                                        item.status === 'evaluating' ? 'info' :
                                                            item.status === 'rejected' ? 'error' :
                                                                item.status === 'paymentpending' ? 'info' :
                                                                    item.status === 'paymentFailed' ? 'error' :
                                                                        item.status === 'paymentSuccessful' ? 'success' : 'info'} >
                                                    {item.status === 'pending' ? 'Pending' :
                                                        item.status === 'evaluating' ? 'Evaluating' :
                                                            item.status === 'rejected' ? 'Rejected' :
                                                                item.status === 'paymentpending' ? 'Payment Pending' :
                                                                    item.status === 'paymentFailed' ? 'Payment Failed' :
                                                                        item.status === 'paymentSuccessful' ? 'Payment Successful' : ' '}
                                                </Label>}
                                            </TableCell>
                                        </TableRow>
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">{t('No data found')}</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="outlined">
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    </BizLayout>
}

export default Finance