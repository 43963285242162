import { Box, Typography, List, ListItem, Grid, ListItemText, Button, Paper } from '@mui/material'
import PaymentsIcon from '@mui/icons-material/Payments';
import OutboxIcon from '@mui/icons-material/Outbox';
import { useTranslation } from 'react-i18next';

const PackageBox = ({ medalType, bgColor, count, count2, letterSpace, handleClickOpen2, features, handleClickOpen }) => {

    const { t } = useTranslation();

    return <Box
        sx={{
            ':hover': {
                transition: "all 0.1s ease",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
            }
        }}
        bgcolor={bgColor} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"}
        borderRadius={2}
        position="relative"
    >
        <Box height={20} />
        <Typography variant="h4" style={{ letterSpacing: letterSpace ? letterSpace : "4.5px" }} color="#000" >
            {t(medalType)} </Typography>
        <Box>
            <List dense={true}>
                {features.map((feature, index) => (
                    <ListItem key={index} style={{ paddingTop: 2, paddingBottom: 2 }}>
                        {/* <ListItemIcon>
                            <KeyboardArrowRightIcon />
                        </ListItemIcon> */}
                        <ListItemText
                            sx={{
                                textAlign: "center",
                                textDecoration: feature.strikeThrough ? "line-through" : "none",
                                lineHeight: 0.5,
                            }}
                            primary={` ◦ ${feature.title}`}
                            secondary={true ? feature.description : null}
                        />
                    </ListItem>
                ))}
            </List>
            <Typography textAlign={"center"} variant="h3" color={"#000"} >{count}</Typography>
            <Typography textAlign={"center"} variant="h5" color={"#000"} >{count2}</Typography>
            <Box height={10} />
            <Grid>
                <Button onClick={() => handleClickOpen({ packageType: medalType })} style={{ marginRight: 20, minWidth: 101 }}
                    variant="outlined" color="primary" size="large"> <OutboxIcon color="white"
                        fontSize="medium" style={{ marginRight: 10 }} />
                    {t("Send")}
                </Button>
                <Button onClick={() => handleClickOpen2({ packageType: medalType })} sx={{ minWidth: 101 }}
                    variant="contained" color="primary" size="large"> <PaymentsIcon color="white"
                        fontSize="medium" style={{ marginRight: 10 }} />
                    {t("Buy")}
                </Button>

            </Grid>

        </Box>
    </Box>
}

export default PackageBox