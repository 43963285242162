import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
  clientListData: null,
  clientListCount: null,
  clientListLoading: false,
  clientListError: null,

  packagesOverviewData: null,
  packagesOverviewLoading: false,
  packagesOverviewError: null,

  userBillingInfoData: null,
  userBillingInfoLoading: false,
  userBillingInfoError: null,

  organizationBillingInfoData: null,
  organizationBillingInfoLoading: false,
  organizationBillingInfoError: null,

  createOrderStripeUserData: null,
  createOrderStripeUserLoading: false,
  createOrderStripeUserError: null,

  afterOrderIyzicoData: null,
  afterOrderIyzicoLoading: false,
  afterOrderIyzicoError: null,

  companyBillingInfoData: null,
  companyBillingInfoLoading: false,
  companyBillingInfoError: null,

  createOrderForOrganizationData: null,
  createOrderForOrganizationLoading: false,
  createOrderForOrganizationError: null,

  updateUserWithdrawalBillingInfoData: null,
  updateUserWithdrawalBillingInfoLoading: false,
  updateUserWithdrawalBillingInfoError: null,

  createCreditWithdrawalRequestData: null,
  createCreditWithdrawalRequestLoading: false,
  createCreditWithdrawalRequestError: null,

  withdrawalsData: null,
  withdrawalsLoading: false,
  withdrawalsCount: null,
  withdrawnsData: null,
  withdrawalsError: null,

  sendPackageData: null,
  sendPackageLoading: false,
  sendPackageError: null,

  sendPackageHistoriesData: null,
  sendPackageHistoriesLoading: false,
  sendPackageHistoriesCount: null,
  sendPackageHistoriesError: null,

  orderData: null,
  orderLoading: false,
  orderError: null,

  sendFreeCreditsData: null,
  sendFreeCreditsLoading: false,
  sendFreeCreditsError: null,

  self_updateUserInvoiceInfoData: null,
  self_updateUserInvoiceInfoLoading: false,
  self_updateUserInvoiceInfoError: null,

  self_updateOrganizationInvoiceInfoData: null,
  self_updateOrganizationInvoiceInfoLoading: false,
  self_updateOrganizationInvoiceInfoError: null,

  self_updateUserBankAccountInfoData: null,
  self_updateUserBankAccountInfoLoading: false,
  self_updateUserBankAccountInfoError: null,

  user_packageHistoryOverviewData: null,
  user_packageHistoryOverviewLoading: false,
  user_packageHistoryOverviewError: null,

  user_revenueHistoryForCreditData: null,
  user_revenueHistoryForCreditCount: null,
  user_revenueHistoryForCreditLoading: false,
  user_revenueHistoryForCreditError: null,

  user_revenueHistoryForPackageData: null,
  user_revenueHistoryForPackageCount: null,
  user_revenueHistoryForPackageLoading: false,
  user_revenueHistoryForPackageError: null,

  user_registeredUsersCreditOverviewData: null,
  user_registeredUsersCreditOverviewLoading: false,
  user_registeredUsersCreditOverviewError: null,

  user_ordersByProductCodeData: null,
  user_ordersByProductCodeCount: null,
  user_ordersByProductCodeLoading: false,
  user_ordersByProductCodeError: null,
};


export const clientListFunc = createAsyncThunk("packages/clientListFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query SalesRep_clients($pagination: Pagination) {
            salesRep_clients(pagination: $pagination) {
              count
              data {
                _id
                displayName
                clientCompanyName
                clientPackageCounts {
                  standard {
                    usable
                  }
                  pro {
                    usable
                  }
                  special {
                    usable
                  }
                }
                totalSalesTo
        
                medal
              }
            }
          }`,
    variables: {
      "pagination": {
        "page": values.page,
        "pageSize": values.pageSize
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const packagesOverviewFunc = createAsyncThunk("packages/packagesOverviewFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Client_dashboardOverviews {
          client_dashboardOverviews {
            packageOverview {
              standard {
                total
                usable
              }
              pro {
                total
                usable
              }
              special {
                total
                usable
              }
            }
          }
        }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const userBillingInfoFunc = createAsyncThunk("packages/userBillingInfoFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Self_user {
      self_user {
        _id
        displayName
        revenue
        withdrawn
        productCode {
          _id
          name
        }
        displayImageUrl
        credit {
          credit {
            total
            usable
            locked
            totalSpent
            withdrawable
            totalWithdrawable
          }
          freeCredit {
            total
            usable
            locked
            totalSpent
          }
        }
        packages {
          standard {
            total
            usable
            locked
            totalSpent
          }
          pro {
            total
            usable
            locked
            totalSpent
          }
          special {
            total
            usable
            locked
            totalSpent
          }
          coffee {
            total
            usable
            locked
            totalSpent
          }
        }
        invoiceInfo {
          type
          personal {
            name
            surname
            email
            id
            phoneNumber
            country
            city
            zip
            address
          }
          business {
            name
            surname
            email
            corporateName
            taxOffice
            taxNumber
            phoneNumber
            country
            city
            zip
            address
          }
        }
        bankAccountInfo {
          type
          currency
          personal {
            name
            surname
            email
            id
            bankName
            bankBranchCode
            iban
            routingNumber
            SWIFTorBICCode
          }
          business {
            bankAccountName
            bankName
            bankBranchCode
            iban
            routingNumber
            SWIFTorBICCode
          }
        }
       # totalSalesAmount
        medal
        registeredBy {
          _id
          displayName
          displayImageUrl
        }
        createdBy {
          _id
          displayName
          displayImageUrl
        }
        updatedBy {
          _id
          displayName
          displayImageUrl
        }
        createdAt
        updatedAt
        role
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }
  ).catch((err) => {

    return err;
  }
  );

  const data = await response;
  return data;
}
);

export const organizationBillingInfoFunc = createAsyncThunk("packages/organizationBillingInfoFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Self_organization {
      self_organization {
        _id
        displayName
        displayImageUrl
        organizationType
        credit {
          credit {
            total
            usable
            locked
            totalSpent
            withdrawable
            totalWithdrawable
          }
          freeCredit {
            total
            usable
            locked
            totalSpent
          }
        }
        packages {
          standard {
            total
            usable
            locked
            totalSpent
          }
          pro {
            total
            usable
            locked
            totalSpent
          }
          special {
            total
            usable
            locked
            totalSpent
          }
          coffee {
            total
            usable
            locked
            totalSpent
          }
        }
        invoiceInfo {
          type
          personal {
            name
            surname
            email
            id
            phoneNumber
            country
            city
            zip
            address
          }
          business {
            name
            surname
            email
            corporateName
            taxOffice
            taxNumber
            phoneNumber
            country
            city
            zip
            address
          }
        }
       # totalSalesAmount
        medal
        registeredBy {
          _id
          displayName
          displayImageUrl
        }
        createdBy {
          _id
          displayName
          displayImageUrl
        }
        updatedBy {
          _id
          displayName
          displayImageUrl
        }
        createdAt
        updatedAt
      }
    } `
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }
  ).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const createOrderStripeUserFunc = createAsyncThunk("packages/createOrderStripeUserFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `mutation User_createOrderForUserWithStripeCheckoutFormPayment($orderInput: CreateOrderWithCheckoutFormInput!) {
      user_createOrderForUserWithStripeCheckoutFormPayment(orderInput: $orderInput) {
        order {
          _id
        }
        paymentResult {
          url
        }
      }
    }`,
    variables: {
      "orderInput": {
        "product": {
          "_id": values.productId,
        }
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }

  ).catch((err) => {

    return err;
  }

  );

  const data = await response;
  return data;
}
);

export const afterOrderIyzicoFunc = createAsyncThunk("packages/afterOrderIyzicoFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `mutation User_updateOrderAfterIyzicoCheckoutFormPayment($orderId: ID!) {
      user_updateOrderAfterIyzicoCheckoutFormPayment(orderId: $orderId) {
        order {
          _id
        }
        paymentResult {
          paymentStatus
        }
      }
    }`,
    variables: {
      "orderId": values.orderId
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }

  ).catch((err) => {

    return err;
  }

  );

  const data = await response;
  return data;
}
);

export const companyBillingInfoFunc = createAsyncThunk("packages/companyBillingInfoFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Self_user {
      self_user {
        billingInfo {
          billingAddress {
            fullName
            email
            country
            city
            address1
            postcode
          }
          buyer {
            name
            surname
            identityNumber
            city
            country
            email
            ip
            registrationAddress
          }
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const createOrderForOrganizationFunc = createAsyncThunk("packages/createOrderForOrganizationFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `mutation User_createOrderForOrganizationWithIyzicoCheckoutFormPayment($orderInput: CreateOrderWithCheckoutFormInput!) {
      user_createOrderForOrganizationWithIyzicoCheckoutFormPayment(orderInput: $orderInput) {
        order {
          _id
        }
        paymentResult {
          paymentPageUrl
        }
      }
    }`,

    variables: {
      "orderInput": {
        "addresses": {
          "billingAddress": {
            "fullName": values.name + " " + values.surname,
            "email": values.email,
            "address1": values.address,
          },
          "shippingAddress": {
            "fullName": values.name + " " + values.surname,
            "email": values.email,
            "address1": values.address
          }
        },
        "product": {
          "_id": values.productId,
        }
      },
      "paymentInput": {
        "paymentChannel": "WEB"
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const updateUserWithdrawalBillingInfoFunc = createAsyncThunk("packages/updateUserWithdrawalBillingInfoFunc", async ({ values, accessToken }) => {

  var data1 = JSON.stringify({
    query: `mutation Self_updateUserWithdrawalBillingInfo($input: WithdrawalBillingInfoInput!) {
      self_updateUserWithdrawalBillingInfo(input: $input) {
        success
      }
    }`,
    variables: {
      "input": {
        "zipCode": values.zipCode,
        "tel": values.tel,
        "surname": values.surname,
        "name": values.name,
        "identificationNumber": values.identificationNumber,
        "email": values.email,
        "country": values.country,
        "city": values.city,
        "bankAccounts": [
          {
            "accountName": values.accountName,
            "accountNumber": values.accountNumber,
            "accountType": values.accountType,
            "bankName": values.bankName,
            "branchCode": values.branchCode,
            "currency": values.currency,
            "iban": values.iban,
            "routingNumber": values.routingNumber,
            "swiftOrBICCode": values.swiftOrBICCode,
            "taxID": values.taxID
          }
        ],
        "address": values.address
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


export const createCreditWithdrawalRequestFunc = createAsyncThunk("packages/createCreditWithdrawalRequestFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `mutation User_createCreditWithdrawalRequest($creditAmount: Int!, $currency: Currency) {
      user_createCreditWithdrawalRequest(creditAmount: $creditAmount, currency: $currency) {
        _id
      }
    }`,
    variables: {
      "creditAmount": Number(values.amount),
      "currency": "USD"
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const withdrawalsFunc = createAsyncThunk("packages/withdrawalsFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query User_creditHistories {
      user_withdrawals {
        count
        data {
          _id
          createdAt
          status
          paymentAmount {
            currency
            amount
          }
        }
      }
      user_withdrawn
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const sendPackageFunc = createAsyncThunk("packages/sendPackageFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `mutation Client_sendPackages($internIds: [ID!]!, $packageType: PackageType!, $packageAmountPerIntern: Int!) {
      client_sendPackages(internIds: $internIds, packageType: $packageType, packageAmountPerIntern: $packageAmountPerIntern) {
        success
      }
    }`,
    variables: {
      "internIds": values.internIds,
      "packageType": values.packageType,
      "packageAmountPerIntern": values.amountPerIntern
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const sendPackageHistoriesFunc = createAsyncThunk("packages/sendPackageHistoriesFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Client_sentPackageHistories($pagination: Pagination) {
      client_sentPackageHistories(pagination: $pagination) {
        count
        data {
          _id
          user {
            displayName
            displayImageUrl
            role
          }
          createdAt
          packageType
        }
      }
    }`,
    variables: {
      "pagination": {
        "page": values.page,
        "pageSize": values.pageSize
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    console.log(err)
    return err;
  });

  const data = await response;
  return data;
});

export const orderFunc = createAsyncThunk("packages/orderFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Order($orderId: ID!) {
      order(orderId: $orderId) {
        orderStatus
      }
    }`,
    variables: {
      "orderId": values?.orderId
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    console.log(err)
    return err;
  });

  const data = await response;
  return data;
});

export const sendFreeCreditsFunc = createAsyncThunk("packages/sendFreeCreditsFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `mutation CompanyRep_sendFreeCredits($companyIds: [ID!]!, $creditAmountPerCompany: Int!) {
      companyRep_sendFreeCredits(companyIds: $companyIds, creditAmountPerCompany: $creditAmountPerCompany) {
        success
      }
    }`,
    variables: {
      "companyIds": [values.companyId],
      "creditAmountPerCompany": values?.creditAmountPerCompany
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    console.log(err)
    return err;
  });

  const data = await response;
  return data;
});

export const self_updateUserInvoiceInfoFunc = createAsyncThunk("packages/self_updateUserInvoiceInfoFunc", async ({ values, accessToken, type }) => {

  var data1 = JSON.stringify({
    query: `mutation Self_updateUserInvoiceInfo($input: InvoiceInfoInput!) {
      self_updateUserInvoiceInfo(input: $input) {
        success
      }
    } `,
    variables: {
      "input": {
        "type": type,
        "personal": type === "personal" ? {
          "address": values.address,
          "city": values.city,
          "country": values.country,
          "email": values.email,
          "id": values.id,
          "name": values.name,
          "phoneNumber": values.phone,
          "surname": values.surname,
          "zip": values.zip
        } : null,
        "business": type === "business" ? {
          "address": values.address,
          "city": values.city,
          "corporateName": values.corporateName,
          "country": values.country,
          "email": values.email,
          "name": values.name,
          "phoneNumber": values.phone,
          "taxNumber": values.taxNumber,
          "surname": values.surname,
          "taxOffice": values.taxOffice,
          "zip": values.zip
        } : null,
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    console.log(err)
    return err;
  });

  const data = await response;
  return data;
});

export const self_updateOrganizationInvoiceInfoFunc = createAsyncThunk("packages/self_updateOrganizationInvoiceInfoFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `mutation Self_updateOrganizationInvoiceInfo($input: InvoiceInfoInput!) {
      self_updateOrganizationInvoiceInfo(input: $input) {
        success
      }
    } `,
    variables: {
      "input": {
        "type": values.type,
        "business": values.type === "business" ? {
          "city": values.city,
          "address": values.address,
          "corporateName": values.corporateName,
          "country": values.country,
          "email": values.email,
          "name": values.name,
          "phoneNumber": values.phoneNumber,
          "surname": values.surname,
          "taxNumber": values.taxNumber,
          "taxOffice": values.taxOffice,
          "zip": values.zip
        } : null,
        "personal": values.type === "personal" ? {
          "address": values.address,
          "city": values.city,
          "country": values.country,
          "email": values.email,
          "id": values.id,
          "name": values.name,
          "phoneNumber": values.phoneNumber,
          "zip": values.zip,
          "surname": values.surname,
        } : null,
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    console.log(err)
    return err;
  });

  const data = await response;
  return data;
});

export const self_updateUserInvoiceInfoFunc2 = createAsyncThunk("packages/self_updateUserInvoiceInfoFunc", async ({ name, surname, accessToken, type }) => {

  var data1 = JSON.stringify({
    query: `mutation Self_updateUserInvoiceInfo($input: InvoiceInfoInput!) {
      self_updateUserInvoiceInfo(input: $input) {
        success
      }
    } `,
    variables: {
      "input": {
        "type": type,
        "personal": type === "personal" ? {
          "name": name,
          "surname": surname
        } : null,
        "business": type === "business" ? {
          "name": name,
          "surname": surname
        } : null,
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    console.log(err)
    return err;
  });

  const data = await response;
  return data;
});

export const self_updateOrganizationInvoiceInfoFunc2 = createAsyncThunk("packages/self_updateOrganizationInvoiceInfoFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `mutation Self_updateOrganizationInvoiceInfo($input: InvoiceInfoInput!) {
      self_updateOrganizationInvoiceInfo(input: $input) {
        success
      }
    } `,
    variables: {
      "input": {
        "type": values.type,
        "business": values.type === "business" ? {
          "name": values.name,
          "surname": values.surname,
        } : null,
        "personal": values.type === "personal" ? {
          "name": values.name,
          "surname": values.surname,
        } : null,
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    console.log(err)
    return err;
  });

  const data = await response;
  return data;
});

export const self_updateUserBankAccountFunc = createAsyncThunk("packages/self_updateUserBankAccountsFunc", async ({ type, accessToken, values }) => {

  console.log(values, '!!!!')
  var data1 = JSON.stringify({
    query: `mutation Self_updateUserBankAccountInfo($input: BankAccountInfoInput!) {
      self_updateUserBankAccountInfo(input: $input) {
        success
      }
    } `,
    variables: {
      "input": {
        "type": type,
        "currency": "USD",
        "business": type === "business" ? {
          "bankAccountName": values.accountName,
          "SWIFTorBICCode": values.swiftOrBICCode,
          "bankBranchCode": values.branchCode,
          "bankName": values.bankName,
          "iban": values.iban,
          "routingNumber": values.routingNumber,
        } : null,
        "personal": type === "personal" ? {
          "name": values.name,
          "surname": values.surname,
          "id": values.id,
          "SWIFTorBICCode": values.swiftOrBICCode,
          "bankBranchCode": values.branchCode,
          "bankName": values.bankName,
          "email": values.email,
          "iban": values.iban,
          "routingNumber": values.routingNumber,
        } : null,
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    console.log(err)
    return err;
  });

  const data = await response;
  return data;
});

export const user_updateOrderAfterStripeCheckoutFormPaymentFunc = createAsyncThunk("packages/user_updateOrderAfterStripeCheckoutFormPaymentFunc", async (values) => {

  const data1 = JSON.stringify({
    query: `mutation User_updateOrderAfterStripeCheckoutFormPayment($orderId: ID!) {
      user_updateOrderAfterStripeCheckoutFormPayment(orderId: $orderId) {
        order {
          _id
        }
        paymentResult {
          paymentStatus
        }
      }
    }`,
    variables: {
      "orderId": values.orderId
    }
  })

  const config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }
  ).catch((err) => {
    return err;
  }
  );

  const data = await response;
  return data;
});

export const user_packageHistoryOverviewFunc = createAsyncThunk("packages/user_packageHistoryOverviewFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query User_packageHistoryOverview {
      user_packageHistoryOverview {
        standard
        pro
        special
      }
    }
    `
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }
  ).catch((err) => {
    return err;
  }
  );

  const data = await response;
  return data;
});

export const user_revenueHistoryForCreditFunc = createAsyncThunk("packages/user_revenueHistoryForCreditFunc", async (values) => {
  var data1 = JSON.stringify({
    query: `query User_revenueHistoryForCredit {
      user_revenueHistoryForCredit {
        count
        data {
          _id
          count
          createdAt
          creditType
          reason
          user {
            _id
            displayImageUrl
            displayName
            role
          }
          relatedOrder {
            price {
              netTotal
              currency
            }
          }
          withdrawableHistory {
            boughtAmount
            boughtProductType
            buyerMedal
            buyerOrg {
              _id
              displayImageUrl
              displayName
            }
            buyerUser {
              _id
              displayImageUrl
              displayName
              role
            }
            medal
            share
            shareType
            sharedCreditAmount
          }
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data?.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const user_revenueHistoryForPackageFunc = createAsyncThunk("packages/user_revenueHistoryForPackageFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query User_revenueHistoryForPackage {
      user_revenueHistoryForPackage {
        count
        data {
          _id
          user {
            _id
            role
            displayName
            displayImageUrl
          }
          count
          creditType
          reason
          relatedOrder {
            price {
              netTotal
              currency
            }
          }
          withdrawableHistory {
            medal
            shareType
            share
            sharedCreditAmount
            buyerUser {
              _id
              role
              displayName
              displayImageUrl
            }
            buyerOrg {
              _id
              displayName
              displayImageUrl
            }
            buyerMedal
            boughtAmount
            boughtProductType
          }
          createdAt
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data?.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const user_registeredUsersCreditOverviewFunc = createAsyncThunk("packages/user_registeredUsersCreditOverviewFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query User_registeredUsersCreditOverview {
      user_registeredUsersCreditOverview {
        purchasedCredit
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data?.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const user_ordersByProductCodeFunc = createAsyncThunk("packages/user_ordersByProductCodeFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query User_ordersByProductCode($orderStatus: OrderStatus, $pagination: Pagination) {
      user_ordersByProductCode(orderStatus: $orderStatus, pagination: $pagination) {
        count
        data {
          _id
          user {
            displayName
           role
          }
          product {
            name
          }
          price {
            netTotal
            currency
          }
          createdAt
        }
      }
    } `,
    variables: {
      "orderStatus": "completed",
      "pagination": {
        "page": values.page,
        "pageSize": values.pageSize
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data?.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});



const packages = createSlice({
  name: "packages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clientListFunc.pending, (state) => {
      state.clientListLoading = true;
    }
    );
    builder.addCase(clientListFunc.fulfilled, (state, action) => {
      state.clientListLoading = false;
      state.clientListData = action.payload?.salesRep_clients?.data;
      state.clientListCount = action.payload?.salesRep_clients?.count;
      state.clientListError = action.payload.message;
    }
    );
    builder.addCase(clientListFunc.rejected, (state, action) => {
      state.clientListLoading = false;
      state.clientListError = action.error.message;
    }
    );
    builder.addCase(packagesOverviewFunc.pending, (state) => {
      state.packagesOverviewLoading = true;
    }
    );
    builder.addCase(packagesOverviewFunc.fulfilled, (state, action) => {
      state.packagesOverviewLoading = false;
      state.packagesOverviewData = action.payload?.client_dashboardOverviews?.packageOverview;
      state.packagesOverviewError = action.payload.message;
    }
    );
    builder.addCase(packagesOverviewFunc.rejected, (state, action) => {
      state.packagesOverviewLoading = false;
      state.packagesOverviewError = action.error.message;
    }
    );


    builder.addCase(userBillingInfoFunc.pending, (state) => {
      state.userBillingInfoLoading = true;
    }
    );
    builder.addCase(userBillingInfoFunc.fulfilled, (state, action) => {
      state.userBillingInfoLoading = false;
      state.userBillingInfoData = action.payload?.self_user;
      state.userBillingInfoError = action.payload.message;
    }
    );
    builder.addCase(userBillingInfoFunc.rejected, (state, action) => {
      state.userBillingInfoLoading = false;
      state.userBillingInfoError = action.error.message;
    }
    );

    builder.addCase(createOrderStripeUserFunc.pending, (state) => {
      state.createOrderStripeUserLoading = true;
    }
    );
    builder.addCase(createOrderStripeUserFunc.fulfilled, (state, action) => {
      state.createOrderStripeUserLoading = false;
      state.createOrderStripeUserData = action.payload?.data?.user_createOrderForUserWithIyzicoCheckoutFormPayment;
      state.createOrderStripeUserError = action.errors;
    }
    );
    builder.addCase(createOrderStripeUserFunc.rejected, (state, action) => {
      state.createOrderStripeUserUserLoading = false;
      state.createOrderStripeUserUserError = action.error.message;
    }
    );

    builder.addCase(afterOrderIyzicoFunc.pending, (state) => {
      state.afterOrderIyzicoLoading = true;
    }
    );
    builder.addCase(afterOrderIyzicoFunc.fulfilled, (state, action) => {
      state.afterOrderIyzicoLoading = false;
      state.afterOrderIyzicoData = action.payload?.data?.user_updateOrderAfterIyzicoCheckoutFormPayment;
      state.afterOrderIyzicoError = action.errors;
    }
    );
    builder.addCase(afterOrderIyzicoFunc.rejected, (state, action) => {
      state.afterOrderIyzicoLoading = false;
      state.afterOrderIyzicoError = action.error.message;
    }
    );

    builder.addCase(companyBillingInfoFunc.pending, (state) => {
      state.companyBillingInfoLoading = true;
    }
    );
    builder.addCase(companyBillingInfoFunc.fulfilled, (state, action) => {
      state.companyBillingInfoLoading = false;
      state.companyBillingInfoData = action.payload?.self_user;
      state.companyBillingInfoError = action.payload.message;
    }
    );
    builder.addCase(companyBillingInfoFunc.rejected, (state, action) => {
      state.companyBillingInfoLoading = false;
      state.companyBillingInfoError = action.error.message;
    }
    );

    builder.addCase(createOrderForOrganizationFunc.pending, (state) => {
      state.createOrderForOrganizationLoading = true;
    }
    );
    builder.addCase(createOrderForOrganizationFunc.fulfilled, (state, action) => {
      state.createOrderForOrganizationLoading = false;
      state.createOrderForOrganizationData = action.payload?.data?.user_createOrderForOrganizationWithIyzicoCheckoutFormPayment;
      state.createOrderForOrganizationError = action.errors;
    }
    );
    builder.addCase(createOrderForOrganizationFunc.rejected, (state, action) => {
      state.createOrderForOrganizationLoading = false;
      state.createOrderForOrganizationError = action.error.message;
    }
    );

    builder.addCase(updateUserWithdrawalBillingInfoFunc.pending, (state) => {
      state.updateUserWithdrawalBillingInfoLoading = true;
    }
    );
    builder.addCase(updateUserWithdrawalBillingInfoFunc.fulfilled, (state, action) => {
      state.updateUserWithdrawalBillingInfoLoading = false;
      state.updateUserWithdrawalBillingInfoData = action.payload?.self_updateUserWithdrawalBillingInfo;
      state.updateUserWithdrawalBillingInfoError = action.errors;
    }
    );
    builder.addCase(updateUserWithdrawalBillingInfoFunc.rejected, (state, action) => {
      state.updateUserWithdrawalBillingInfoLoading = false;
      state.updateUserWithdrawalBillingInfoError = action.error.message;
    }
    );

    builder.addCase(createCreditWithdrawalRequestFunc.pending, (state) => {
      state.createCreditWithdrawalRequestLoading = true;
    }
    );
    builder.addCase(createCreditWithdrawalRequestFunc.fulfilled, (state, action) => {
      state.createCreditWithdrawalRequestLoading = false;
      state.createCreditWithdrawalRequestData = action.payload?.data?.user_createCreditWithdrawalRequest;
      state.createCreditWithdrawalRequestError = action.errors;
    }
    );
    builder.addCase(createCreditWithdrawalRequestFunc.rejected, (state, action) => {
      state.createCreditWithdrawalRequestLoading = false;
      state.createCreditWithdrawalRequestError = action.error.message;
    }
    );

    builder.addCase(withdrawalsFunc.pending, (state) => {
      state.withdrawalsLoading = true;
    }
    );
    builder.addCase(withdrawalsFunc.fulfilled, (state, action) => {
      state.withdrawalsLoading = false;
      state.withdrawalsData = action.payload?.user_withdrawals?.data;
      state.withdrawalsCount = action.payload?.user_withdrawals?.count;
      state.withdrawnsData = action.payload.user_withdrawn;
      state.withdrawalsError = action.errors;
    }
    );
    builder.addCase(withdrawalsFunc.rejected, (state, action) => {
      state.withdrawalsLoading = false;
      state.withdrawalsError = action.error.message;
    }
    );

    builder.addCase(sendPackageFunc.pending, (state) => {
      state.sendPackageLoading = true;
    }
    );
    builder.addCase(sendPackageFunc.fulfilled, (state, action) => {
      state.sendPackageLoading = false;
      state.sendPackageData = action.payload?.client_sendPackages;
      state.sendPackageError = action.errors;
    }
    );
    builder.addCase(sendPackageFunc.rejected, (state, action) => {
      state.sendPackageLoading = false;
      state.sendPackageError = action.error.message;
    }
    );

    builder.addCase(sendPackageHistoriesFunc.pending, (state) => {
      state.sendPackageHistoriesLoading = true;
    }
    );
    builder.addCase(sendPackageHistoriesFunc.fulfilled, (state, action) => {
      state.sendPackageHistoriesLoading = false;
      state.sendPackageHistoriesData = action.payload?.client_sentPackageHistories?.data;
      state.sendPackageHistoriesCount = action.payload?.client_sentPackageHistories?.count;
      state.sendPackageHistoriesError = action.errors;
    }
    );
    builder.addCase(sendPackageHistoriesFunc.rejected, (state, action) => {
      state.sendPackageHistoriesLoading = false;
      state.sendPackageHistoriesError = action.error.message;
    }
    );

    builder.addCase(orderFunc.pending, (state) => {
      state.orderLoading = true;
    }
    );
    builder.addCase(orderFunc.fulfilled, (state, action) => {
      state.orderLoading = false;
      state.orderData = action.payload?.order;
      state.orderError = action.errors;
    }
    );
    builder.addCase(orderFunc.rejected, (state, action) => {
      state.orderLoading = false;
      state.orderError = action.error.message;
    }
    );

    builder.addCase(sendFreeCreditsFunc.pending, (state) => {
      state.sendFreeCreditsLoading = true;
    }
    );
    builder.addCase(sendFreeCreditsFunc.fulfilled, (state, action) => {
      state.sendFreeCreditsLoading = false;
      state.sendFreeCreditsData = action.payload?.data?.companyRep_sendFreeCredits;
      state.sendFreeCreditsError = action.errors;
    }
    );
    builder.addCase(sendFreeCreditsFunc.rejected, (state, action) => {
      state.sendFreeCreditsLoading = false;
      state.sendFreeCreditsError = action.error.message;
    }
    );

    builder.addCase(self_updateUserInvoiceInfoFunc.pending, (state) => {
      state.self_updateUserInvoiceInfoLoading = true;
    }
    );
    builder.addCase(self_updateUserInvoiceInfoFunc.fulfilled, (state, action) => {
      state.self_updateUserInvoiceInfoLoading = false;
      state.self_updateUserInvoiceInfoData = action.payload?.self_updateUserInvoiceInfo;
      state.self_updateUserInvoiceInfoError = action.errors;
    }
    );
    builder.addCase(self_updateUserInvoiceInfoFunc.rejected, (state, action) => {
      state.self_updateUserInvoiceInfoLoading = false;
      state.self_updateUserInvoiceInfoError = action.error.message;
    }
    );

    builder.addCase(self_updateOrganizationInvoiceInfoFunc.pending, (state) => {
      state.self_updateOrganizationInvoiceInfoLoading = true;
    }
    );

    builder.addCase(self_updateOrganizationInvoiceInfoFunc.fulfilled, (state, action) => {
      state.self_updateOrganizationInvoiceInfoLoading = false;
      state.self_updateOrganizationInvoiceInfoData = action.payload?.self_updateOrganizationInvoiceInfo;
      state.self_updateOrganizationInvoiceInfoError = action.errors;
    }
    );

    builder.addCase(self_updateOrganizationInvoiceInfoFunc.rejected, (state, action) => {
      state.self_updateOrganizationInvoiceInfoLoading = false;
      state.self_updateOrganizationInvoiceInfoError = action.error.message;
    }
    );

    builder.addCase(self_updateUserBankAccountFunc.pending, (state) => {
      state.self_updateUserBankAccountInfoLoading = true;
    }
    );

    builder.addCase(self_updateUserBankAccountFunc.fulfilled, (state, action) => {
      state.self_updateUserBankAccountInfoLoading = false;
      state.self_updateUserBankAccountInfoData = action.payload?.self_updateUserBankAccountInfo;
      state.self_updateUserBankAccountInfoError = action.errors;
    }
    );

    builder.addCase(self_updateUserBankAccountFunc.rejected, (state, action) => {
      state.self_updateUserBankAccountInfoLoading = false;
      state.self_updateUserBankAccountInfoError = action.error.message;
    }
    );

    builder.addCase(organizationBillingInfoFunc.pending, (state) => {
      state.organizationBillingInfoLoading = true;
    }
    );
    builder.addCase(organizationBillingInfoFunc.fulfilled, (state, action) => {
      state.organizationBillingInfoLoading = false;
      state.organizationBillingInfoData = action.payload?.self_organization;
      state.organizationBillingInfoError = action.payload.message;
    }
    );
    builder.addCase(organizationBillingInfoFunc.rejected, (state, action) => {
      state.organizationBillingInfoLoading = false;
      state.organizationBillingInfoError = action.error.message;
    }
    );

    builder.addCase(user_updateOrderAfterStripeCheckoutFormPaymentFunc.pending, (state) => {
      state.user_updateOrderAfterStripeCheckoutFormPaymentLoading = true;
    }
    );
    builder.addCase(user_updateOrderAfterStripeCheckoutFormPaymentFunc.fulfilled, (state, action) => {
      state.user_updateOrderAfterStripeCheckoutFormPaymentLoading = false;
      state.user_updateOrderAfterStripeCheckoutFormPaymentData = action.payload?.user_updateOrderAfterStripeCheckoutFormPayment;
      state.user_updateOrderAfterStripeCheckoutFormPaymentError = action.errors;
    }
    );
    builder.addCase(user_updateOrderAfterStripeCheckoutFormPaymentFunc.rejected, (state, action) => {
      state.user_updateOrderAfterStripeCheckoutFormPaymentLoading = false;
      state.user_updateOrderAfterStripeCheckoutFormPaymentError = action.error.message;
    }
    );

    builder.addCase(user_packageHistoryOverviewFunc.pending, (state) => {
      state.user_packageHistoryOverviewLoading = true;
    }
    );
    builder.addCase(user_packageHistoryOverviewFunc.fulfilled, (state, action) => {
      state.user_packageHistoryOverviewLoading = false;
      state.user_packageHistoryOverviewData = action.payload?.user_packageHistoryOverview;
      state.user_packageHistoryOverviewError = action.errors;
    }
    );
    builder.addCase(user_packageHistoryOverviewFunc.rejected, (state, action) => {
      state.user_packageHistoryOverviewLoading = false;
      state.user_packageHistoryOverviewError = action.error.message;
    }
    );

    builder.addCase(user_revenueHistoryForCreditFunc.pending, (state) => {
      state.user_revenueHistoryForCreditLoading = true;
    }
    );
    builder.addCase(user_revenueHistoryForCreditFunc.fulfilled, (state, action) => {
      state.user_revenueHistoryForCreditLoading = false;
      state.user_revenueHistoryForCreditData = action.payload?.user_revenueHistoryForCredit?.data;
      state.user_revenueHistoryForCreditCount = action.payload?.user_revenueHistoryForCredit?.count;
      state.user_revenueHistoryForCreditError = action.errors;
    }
    );
    builder.addCase(user_revenueHistoryForCreditFunc.rejected, (state, action) => {
      state.user_revenueHistoryForCreditLoading = false;
      state.user_revenueHistoryForCreditError = action.error.message;
    }
    );

    builder.addCase(user_revenueHistoryForPackageFunc.pending, (state) => {
      state.user_revenueHistoryForPackageLoading = true;
    }
    );
    builder.addCase(user_revenueHistoryForPackageFunc.fulfilled, (state, action) => {
      state.user_revenueHistoryForPackageLoading = false;
      state.user_revenueHistoryForPackageData = action.payload?.user_revenueHistoryForPackage?.data;
      state.user_revenueHistoryForPackageCount = action.payload?.user_revenueHistoryForPackage?.count;
      state.user_revenueHistoryForPackageError = action.errors;
    }
    );
    builder.addCase(user_revenueHistoryForPackageFunc.rejected, (state, action) => {
      state.user_revenueHistoryForPackageLoading = false;
      state.user_revenueHistoryForPackageError = action.error.message;
    }
    );

    builder.addCase(user_registeredUsersCreditOverviewFunc.pending, (state) => {
      state.user_registeredUsersCreditOverviewLoading = true;
    }
    );
    builder.addCase(user_registeredUsersCreditOverviewFunc.fulfilled, (state, action) => {
      state.user_registeredUsersCreditOverviewLoading = false;
      state.user_registeredUsersCreditOverviewData = action.payload?.user_registeredUsersCreditOverview;
      state.user_registeredUsersCreditOverviewError = action.errors;
    }
    );
    builder.addCase(user_registeredUsersCreditOverviewFunc.rejected, (state, action) => {
      state.user_registeredUsersCreditOverviewLoading = false;
      state.user_registeredUsersCreditOverviewError = action.error.message;
    }
    );

    builder.addCase(user_ordersByProductCodeFunc.pending, (state) => {
      state.user_ordersByProductCodeLoading = true;
    }
    );
    builder.addCase(user_ordersByProductCodeFunc.fulfilled, (state, action) => {
      state.user_ordersByProductCodeLoading = false;
      console.log(action.payload, 'action.payload')
      state.user_ordersByProductCodeData = action.payload?.user_ordersByProductCode?.data;
      state.user_ordersByProductCodeCount = action.payload?.user_ordersByProductCode?.count;
      state.user_ordersByProductCodeError = action.errors;
    }
    );
    builder.addCase(user_ordersByProductCodeFunc.rejected, (state, action) => {
      state.user_ordersByProductCodeLoading = false;
      state.user_ordersByProductCodeError = action.error.message;
    }
    );

  }
});


export default packages.reducer;