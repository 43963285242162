import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const BasicInput = ({
    name = "name",
    label = "Name",
    placeholder = "Enter your name",

    error = false,
    value = "",
    handleBlur = () => null,
    handleChange = () => null,

    disabled,
}) => {

    const [showPassword, setShowPassword] = useState(false);


    return <Box>


        <Typography variant='h7' color={value ? 'primary.light' : 'primary.main'} >
            {label}
        </Typography>

        <Box
            sx={{
                borderRadius: '12px',
                border: '1px solid',
                borderColor: error ? 'error.main' : 'primary.light',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#fff',
                height: error ? "46px" : "48px",
            }}
        >
            <input
                disabled={disabled}
                name={name}
                className="input_placeholder"
                type={showPassword ? "text" : "password"}
                placeholder={placeholder}
                style={{
                    border: 'none',
                    outline: 'none',
                    width: '100%',
                    fontSize: '14px',
                    lineHeight: '24px',
                    paddingLeft: '16px',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                    fontFamily: 'Causten-SemiBold',
                    color: '#0E1436',
                }}

                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <Box
                sx={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '12px',
                    marginRight: '8px',
                    cursor: 'pointer',
                }}
            >
                {showPassword ?
                    <VisibilityIcon sx={{ color: 'primary.light' }} onClick={() => setShowPassword(!showPassword)} /> :
                    <VisibilityOffIcon sx={{ color: 'primary.light' }} onClick={() => setShowPassword(!showPassword)} />
                }
            </Box>

        </Box>
        <Box height={17}>
            {
                error && <Typography sx={{
                    fontFamily: 'Causten-Medium',
                    color: 'error.main',
                    fontSize: { xs: '12px' },
                    lineHeight: { xs: '20px' },
                    textAlign: 'left',
                    mt: '2px',
                    marginLeft: '16px',
                }}>
                    {error}
                </Typography>
            }
        </Box>
    </Box>
}

export default BasicInput