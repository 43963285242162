import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";


const initialState = {
  productsData: null,
  productsLoading: false,
  productsError: null,
};


export const productsFunc = createAsyncThunk("products/productsFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Data {
      user_products {
        data {
      _id
          discount
          currency
          unitPrice
          price
          name
          amount
          listUnitPrice
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});



const products = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(productsFunc.pending, (state) => {
      state.productsLoading = true;
    });
    builder.addCase(productsFunc.fulfilled, (state, action) => {
      state.productsLoading = false;
      state.productsData = action.payload?.user_products?.data;
      state.productsError = action.payload?.errors
    });
    builder.addCase(productsFunc.rejected, (state, action) => {
      state.productsLoading = false;
      state.productsError = action.error;
    });
  }
});


export default products.reducer;