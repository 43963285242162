import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BizLayout from "layouts/biz/BizLayout"
import {
    Box, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, CircularProgress, Pagination, Skeleton
} from '@mui/material'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSnackbar } from 'notistack';
import { companyRep_registerFunc, pendingUsersFunc } from "context/auth/auth";
import { sendFreeCreditsFunc } from "context/common/packages";
import { companyListFunc, companiesOverviewFunc } from "context/companyRep/company";
import { MedalBox, CompanyAdminListTable, PendingListTable } from "components";
import { useTranslation } from 'react-i18next';
import FileCopy from '@mui/icons-material/FileCopy';
import { createNoteToUserFunc, getUserNotesToUserFunc, updateNoteFunc } from 'context/common/notes';

const info = [
    "Type'la ilgili problemi backend çözdüğü zaman yeniden bakılacak.",
    "companiesOverview sayıları backendden sonra kontrol edilecek."
]

const validationSchema = Yup.object({
    name: Yup.string().transform((originalValue, originalObject) => {
        if (!originalValue) {
            return undefined;
        }
        if (originalValue.length < 2) {
            throw new Yup.ValidationError('Name must be at least 2 characters long', originalValue, 'name');
        }
        if (!/^[a-zA-ZğüşıöçĞÜŞİÖÇ.,\s]*$/.test(originalValue)) {
            throw new Yup.ValidationError('Name can\'t contain special characters', originalValue, 'name');
        }
        return originalValue;
    }).required('Name is required'),
    surname: Yup.string().transform((originalValue, originalObject) => {
        if (!originalValue) {
            return undefined;
        }
        if (originalValue.length < 2) {
            throw new Yup.ValidationError('Surname must be at least 2 characters long', originalValue, 'surname');
        }
        if (!/^[a-zA-ZğüşıöçĞÜŞİÖÇ.,\s]*$/.test(originalValue)) {
            throw new Yup.ValidationError('Surname can\'t contain special characters', originalValue, 'surname');
        }
        return originalValue;
    }).required('Surname is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const pageSize = 10;

const Company = () => {

    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken, companyRep_registerLoading, companyRep_registerData } = useSelector((state) => state.auth);
    const { companyListData, companyListCount, companiesOverviewData } = useSelector((state) => state.company);
    const { pendingUsersData, pendingUsersCount } = useSelector((state) => state.auth);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [pagePending, setPagePending] = useState(1);
    const { getUserNotesToUserData } = useSelector((state) => state.notes);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openSendCredit, setOpenSendCredit] = useState(false);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        formikNote.resetForm();
    };

    const handleClickOpenSendCredit = (item) => {
        setOpenSendCredit(true);
        setSelectedItem(item);
    };

    const handleChangePending = (event, value) => {
        setPagePending(value);
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            email: '',
            role: 'companyAdmin',
            accessToken: accessToken
        },
        validationSchema,
        onSubmit: (values) => {
            dispatch(companyRep_registerFunc(values)).then((res) => {
                if (res?.payload.isSuccess) {
                    enqueueSnackbar('Company added successfully', { variant: 'success' });

                    process.env.REACT_APP_TEST === "true" && enqueueSnackbar(<div>
                        {"Registration link: "}
                        <Button
                            color="secondary"
                            size="small"
                            onClick={() => {
                                const textToCopy = `http://biz.internif.com/create-new-password/?token=${res?.payload.data.token}&reset=${res?.payload.data.resetPasswordToken}&code=${res?.payload.data.code}`
                                const textArea = document.createElement('textarea');
                                textArea.value = textToCopy;
                                document.body.appendChild(textArea);
                                textArea.select();
                                document.execCommand('copy');
                                document.body.removeChild(textArea);
                            }}
                        >
                            Copy Link
                            <FileCopy />
                        </Button>
                    </div>, { variant: 'warning', autoHideDuration: 6000 });

                    handleClose();
                } else if (res?.payload?.response?.data?.errors?.[0]?.msg === 'Email already in use') {
                    enqueueSnackbar("Email already in use!", { variant: 'error' });
                } else {
                    enqueueSnackbar('Something went wrong', { variant: 'error' });
                }
            }).catch((err) => {
                enqueueSnackbar('Something went wrong', { variant: 'error' });
            });
        },
    });


    const handleClickOpenEdit = (item) => {
        setOpenEdit(true);
        setSelectedItem(item);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setSelectedItem(null);
        formik.resetForm();
    };

    const handleSendCredit = (userId, credit) => {

        dispatch(sendFreeCreditsFunc({ accessToken: accessToken, companyId: userId, creditAmountPerCompany: credit })).then((res) => {
            setOpenSendCredit(false);
            if (res?.payload?.data?.companyRep_sendFreeCredits?.success) {
                enqueueSnackbar('Credit sent successfully', { variant: 'success' });
            } else {
                console.log(res.payload.errors, 'sadf')
                enqueueSnackbar(res.payload.errors?.[0]?.message === "FreeCreditSendLimitExceeded" ? 'Free credit send limit exceeded'
                    : 'Something went wrong', { variant: 'error' });
            }
        }).catch((err) => {
            console.log(err, 'err')
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        });
    }


    const formikNote = useFormik({
        enableReinitialize: true,
        initialValues: {
            note: getUserNotesToUserData?.content ?? '',
        },
        onSubmit: (values) => {
            getUserNotesToUserData ?
                dispatch(updateNoteFunc({
                    content: values.note,
                    accessToken: accessToken,
                    noteId: getUserNotesToUserData._id
                })).then((res) => {
                    dispatch(getUserNotesToUserFunc({ accessToken: accessToken, targetId: selectedItem?.companyAdmin?._id }));
                    enqueueSnackbar('Note updated successfully', { variant: 'success' });
                    handleCloseEdit();
                }).catch((err) => {
                    enqueueSnackbar('Something went wrong', { variant: 'error' });
                })
                :
                dispatch(createNoteToUserFunc({ accessToken: accessToken, title: selectedItem?.companyAdmin?._id, targetId: selectedItem?.companyAdmin?._id, content: values.note })).then((res) => {

                    if (res?.payload?.user_createNoteToUser?._id) {
                        enqueueSnackbar('Note added successfully', { variant: 'success' });
                        handleCloseEdit();
                    } else {
                        enqueueSnackbar('Something went wrong', { variant: 'error' });
                    }
                }).catch((err) => {
                    enqueueSnackbar('Something went wrong', { variant: 'error' });
                });
        },
    });

    useEffect(() => {
        accessToken && selectedItem && dispatch(getUserNotesToUserFunc({ accessToken: accessToken, targetId: selectedItem?.companyAdmin?._id }));
    }, [selectedItem, accessToken, dispatch])
    useEffect(() => {
        dispatch(companiesOverviewFunc({ accessToken: accessToken }));
        dispatch(companyListFunc({ accessToken: accessToken, page: page, pageSize: pageSize }));
    }, [dispatch, accessToken, page, companyRep_registerData]);

    useEffect(() => {
        dispatch(pendingUsersFunc({ accessToken: accessToken, status: "pending", role: 'companyAdmin', page: pagePending, pageSize: pageSize }));
    }, [dispatch, accessToken, pagePending, companyRep_registerData]);

    console.log(selectedItem?._id)

    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("Company")}</Typography>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t('Bronze')}
                        bgColor="#FFEFE2"
                        count={companiesOverviewData?.bronzeCount || 0}
                        uppercase
                        label={t("Bronze")}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t('Silver')}
                        bgColor="#F5F5F5"
                        count={companiesOverviewData?.silverCount || 0}
                        uppercase
                        label={t("Silver")}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t('Gold')}
                        bgColor="#fff1ba"
                        count={companiesOverviewData?.goldCount || 0}
                        uppercase
                        label={t("Gold")}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t('VIP')}
                        bgColor="#383e42"
                        count={companiesOverviewData?.vipCount || 0}
                        uppercase
                        label={t("VIP")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={3} alignItems="center">
                <Grid item xs={6} >
                    <Typography variant="h5">{t("Company List")}</Typography>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent={"end"}>
                    <Button onClick={handleClickOpen} variant="contained" color="primary" size="large">
                        <AddCircleOutlineIcon color="white" fontSize="medium" style={{ marginRight: 5 }} />
                        {t("Add Company")}
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={0} mt={3} sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
            }} component={Paper} >
                <CompanyAdminListTable
                    data={companyListData}
                    dataCount={companyListCount}
                    page={page}
                    pageSize={pageSize}
                    handleChangePage={handleChange}
                    handleClickOpenEdit={handleClickOpenEdit}
                    handleClickOpenSendCredit={handleClickOpenSendCredit}

                />
            </Grid>

            <Grid container spacing={2} mt={3} alignItems="center">
                <Grid item xs={6} >
                    <Typography variant="h5">{t("Pending Company List")}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={0} mt={3} sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
            }} component={Paper} >
                <PendingListTable
                    data={pendingUsersData}
                    page={pagePending}
                    pageSize={pageSize}
                    dataCount={pendingUsersCount}
                    handleChangePage={handleChangePending}
                />
            </Grid>

            <Box height={40} />
        </Box>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle id="form-dialog-title">{t("Add Company")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("To add a company, please enter the company information here.")}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        inputProps={{ maxLength: 35 }}
                        id="name"
                        name="name"
                        label={t("Name")}
                        type="text"
                        fullWidth
                        value={formik.values.name}
                        onChange={(e) => {
                            const capitalizedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                            formik.setFieldValue('name', capitalizedValue);
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />

                    <TextField
                        margin="dense"
                        inputProps={{ maxLength: 35 }}
                        id="surname"
                        name="surname"
                        label={t("Surname")}
                        type="text"
                        fullWidth
                        value={formik.values.surname}
                        onChange={(e) => {
                            const capitalizedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                            formik.setFieldValue('surname', capitalizedValue);
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched.surname && Boolean(formik.errors.surname)}
                        helperText={formik.touched.surname && formik.errors.surname}
                    />

                    <TextField
                        margin="dense"
                        inputProps={{ maxLength: 35 }}
                        id="email"
                        name="email"
                        label={t("Email")}
                        type="email"
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        {t("Cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary"
                        disabled={companyRep_registerLoading}
                    >
                        {companyRep_registerLoading ? <CircularProgress size={20} /> : t("Add")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

        <Dialog open={openEdit}
            onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
            <form onSubmit={formikNote.handleSubmit}>
                <DialogTitle id="form-dialog-title">{t("Notes")}</DialogTitle>
                <DialogContent>
                    <DialogContentText mb={1} fontWeight="bold">
                        {selectedItem?.displayName}
                    </DialogContentText>

                    <TextField
                        margin="dense"
                        id="note"
                        name="note"
                        label={t("Note")}
                        placeholder="You can write your notes here."
                        type="text"
                        sx={{ minWidth: { xs: '100%', md: 400 } }}
                        multiline
                        rows={4}
                        value={formikNote.values.note}
                        onChange={formikNote.handleChange}
                        onBlur={formikNote.handleBlur}
                        error={formikNote.touched.note && Boolean(formikNote.errors.note)}
                        helperText={formikNote.touched.note && formikNote.errors.note}
                    />



                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        {t('Cancel')}
                    </Button>
                    <Button type="submit" variant="contained" color="primary"
                        disabled={null}
                    >
                        {false ? <CircularProgress size={20} /> : "Save"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

        <Dialog open={openSendCredit}
            onClose={() => setOpenSendCredit(false)} aria-labelledby="form-dialog-title">
            <form onSubmit={formikNote.handleSubmit}>
                <DialogTitle id="form-dialog-title">{t("Send Credit")}</DialogTitle>
                <DialogContent>
                    <DialogContentText mb={1} fontWeight="bold">
                        Are you sure you want to send 50 free credit to user named {selectedItem?.displayName}?
                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSendCredit(false)} color="primary" variant="outlined">
                        {t('Cancel')}
                    </Button>
                    <Button variant="contained" color="primary"
                        onClick={() => {
                            handleSendCredit(selectedItem?._id, 50)
                            setOpenSendCredit(false)
                        }}
                        disabled={null}
                    >
                        {false ? <CircularProgress size={20} /> : "Send"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

    </BizLayout>
}

export default Company