import { Button, CircularProgress, Typography, Box } from '@mui/material'

const BlackButton = ({ isActive = true, handleSubmit, loading, name, type }) => {


    return (
        <Button
            variant='contained'
            color='primary'
            disabled={!isActive || loading}
            onClick={handleSubmit}
            type={type}
            sx={{
                width: '100%',
                borderRadius: '12px',
                textTransform: 'none',
                marginTop: '10px',
                gap: 2,
                height: '48px',
            }}
        >
            <Typography variant='h6' >
                {loading ? <Box mt={1}><CircularProgress size={20} pt={10} /></Box> : name}
            </Typography>
        </Button>
    )
}

export default BlackButton