import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
  internListData: null,
  internListCount: null,
  internListLoading: false,
  internListError: null,
  internsOverviewData: null,
  internsOverviewLoading: false,
  internsOverviewError: null,
  internRep_hInternsData: null,
  internRep_hInternsCount: null,
  internRep_hInternsLoading: false,
  internRep_hInternsError: null,
};


export const internListFunc = createAsyncThunk("company/internListFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query InternRep_interns($pagination: Pagination) {
      internRep_interns(pagination: $pagination) {
        count
        data {
          _id
          displayName
          displayImageUrl
          contact {
            email
          }
          medal
          createdAt
        }
      }
    }`,
    variables: {
      "pagination": {
        "page": values.page,
        "pageSize": values.pageSize
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


export const internsOverviewFunc = createAsyncThunk("company/internsOverviewFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query InternRep_dashboardOverviews {
      internRep_dashboardOverviews {
        internsOverview {
          bronzeCount
          silverCount
          goldCount
          vipCount
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const internRep_hInternsFunc = createAsyncThunk("company/internRep_hInternsFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Data($pagination: Pagination) {
      internRep_hInterns(pagination: $pagination) {
        data {
          _id
          displayName
          displayImageUrl
          contact {
            email
          }
          medal
          createdAt
        }
        count
      }
    }`,
    variables: {
      "pagination": {
        "page": values.page,
        "pageSize": values.pageSize
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});





const company = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(internListFunc.pending, (state) => {
      state.internListLoading = true;
    }
    );
    builder.addCase(internListFunc.fulfilled, (state, action) => {
      state.internListLoading = false;
      state.internListData = action.payload?.internRep_interns?.data;
      state.internListCount = action.payload?.internRep_interns?.count;
      state.internListError = action.payload.message;
    }
    );
    builder.addCase(internListFunc.rejected, (state, action) => {
      state.internListLoading = false;
      state.internListError = action.error.message;
    }
    );
    builder.addCase(internsOverviewFunc.pending, (state) => {
      state.internsOverviewLoading = true;
    }
    );
    builder.addCase(internsOverviewFunc.fulfilled, (state, action) => {
      state.internsOverviewLoading = false;
      state.internsOverviewData = action.payload?.internRep_dashboardOverviews?.internsOverview;
      state.internsOverviewError = action.payload.message;
    }
    );
    builder.addCase(internsOverviewFunc.rejected, (state, action) => {
      state.internsOverviewLoading = false;
      state.internsOverviewError = action.error.message;
    }
    );

    builder.addCase(internRep_hInternsFunc.pending, (state) => {
      state.internRep_hInternsLoading = true;
    }
    );
    builder.addCase(internRep_hInternsFunc.fulfilled, (state, action) => {
      state.internRep_hInternsLoading = false;
      state.internRep_hInternsData = action.payload?.internRep_hInterns?.data;
      state.internRep_hInternsCount = action.payload?.internRep_hInterns?.count;
      state.internRep_hInternsError = action.payload.message;
    }
    );
    builder.addCase(internRep_hInternsFunc.rejected, (state, action) => {
      state.internRep_hInternsLoading = false;
      state.internRep_hInternsError = action.error.message;
    }
    );

  }
});


export default company.reducer;