import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Routes } from 'react-router-dom'
import { setUserRole, setUserId, setAccessToken, setEmail } from 'context/auth/auth';
import { getUserFunc, getInHouseUserFunc, getDefaultLanguageFunc } from 'context/common/user';
import { universitiesOverviewFunc } from 'context/salesRep/university';
import { clientsOverviewFunc } from 'context/salesRep/client';
import { companiesOverviewFunc } from 'context/companyRep/company';
import { SalesRepDashboard, SalesRepOnboarding } from 'routes/SalesRepRouter';
import { CompanyRepDashboard, CompanyRepOnboarding } from 'routes/CompanyRepRouter';
import { InternRepDashboard, InternRepOnboarding } from 'routes/InternRepRouter';
import { ClientDashboard, ClientOnboarding } from 'routes/ClientRouter';
import AnonymousRouter from 'routes/AnonymousRouter';
import { useTranslation } from 'react-i18next';
import { packagesOverviewFunc, userBillingInfoFunc } from 'context/common/packages';
import { getUserNotesToSelfFunc } from 'context/common/notes';

const Router = () => {

    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { userRole, accessToken } = useSelector(state => state.auth);
    const { onboarded, getDefaultLanguageData } = useSelector(state => state.user);

    useEffect(() => {
        const lang = getDefaultLanguageData || 'en';

        i18n.changeLanguage(lang);

    }, [i18n, getDefaultLanguageData])


    useEffect(() => {
        dispatch(setUserRole(localStorage.getItem('userRole')))
        dispatch(setUserId(localStorage.getItem('userId')))
        dispatch(setAccessToken(localStorage.getItem('accessToken')))
        dispatch(setEmail(localStorage.getItem('email')))
    }, [dispatch])

    useEffect(() => {
        accessToken && dispatch(getUserFunc({ accessToken }))
        accessToken && dispatch(getInHouseUserFunc({ accessToken }))
        accessToken && dispatch(getDefaultLanguageFunc({ accessToken }))
        accessToken && dispatch(getUserNotesToSelfFunc({ accessToken: accessToken }));
        accessToken && userRole === 'b2bSalesRep' && dispatch(universitiesOverviewFunc({ accessToken: accessToken }));
        accessToken && userRole === 'b2bSalesRep' && dispatch(clientsOverviewFunc({ accessToken: accessToken }));
        accessToken && userRole === 'b2bCompanyRep' && dispatch(companiesOverviewFunc({ accessToken: accessToken }));
        accessToken && userRole === "b2bClient" && dispatch(packagesOverviewFunc({ accessToken: accessToken }));
        accessToken && dispatch(userBillingInfoFunc({ accessToken: accessToken }));
    }, [accessToken, dispatch, userRole])

    const renderUserTypeRouter = () => {
        switch (userRole) {
            case 'b2bSalesRep':
                switch (onboarded) {
                    case "completed":
                        return SalesRepDashboard();
                    case "pending":
                        return SalesRepOnboarding();
                    default:
                        return null;
                }
            case 'b2bCompanyRep':
                switch (onboarded) {
                    case "completed":
                        return CompanyRepDashboard();
                    case "pending":
                        return CompanyRepOnboarding();
                    default:
                        return null;
                }
            case 'b2bInternRep':
                switch (onboarded) {
                    case "completed":
                        return InternRepDashboard();
                    case "pending":
                        return InternRepOnboarding();
                    default:
                        return null;
                }
            case 'b2bClient':
                switch (onboarded) {
                    case "completed":
                        return ClientDashboard();
                    case "pending":
                        return ClientOnboarding();
                    default:
                        return null;
                }
            default:
                return AnonymousRouter();
        }
    }

    return (
        <BrowserRouter>
            <Routes>
                {renderUserTypeRouter()}
            </Routes>
        </BrowserRouter>
    )

}

export default Router;