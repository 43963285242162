import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import auth from "./auth/auth";

import client from "./salesRep/client"
import university from "./salesRep/university"

import parameters from "./common/parameters";
import user from "./common/user";
import products from "./common/products";
import notes from "./common/notes";

import company from "./companyRep/company";

import intern from "./internRep/intern";

import packages from "./common/packages";

import finance from "./client/finance";


const store = configureStore({
    reducer: {
        auth,
        client,
        university,
        parameters,
        user,
        company,
        intern,
        packages,
        products,
        notes,
        finance
    },
    middleware: [thunk],
    devTools: true
});


export default store;
