import { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material'
import { useMediaQuery } from '@mui/material';
import BackToHome from 'components/Buttons/BackToHome';
import ExittoOnboarding from 'components/Buttons/ExittoOnboarding';
import { Onboarding, BackgroundImage, AuthImage4, AuthImage2, AuthBiz, CreateNewPassword } from 'assets'
import AuthSlider from 'components/Sections/AuthSlider';
import PageOpening from 'components/Motions/PageOpening';
import { useLocation } from 'react-router-dom';
import InformationDrawer from "components/Dialogs/InformationDrawer";

const OnboardingLayout = ({ children, info }) => {

  const path = useLocation().pathname.split('/')[1];

  const [pageHeight, setPageHeight] = useState(window.innerHeight);

  const downXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const updatePageHeight = () => {
    setPageHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageHeight);

    return () => {
      window.removeEventListener('resize', updatePageHeight);
    };
  }, []);

  return <PageOpening>
    {process.env.REACT_APP_TEST === "true" && info && <InformationDrawer info={info} />}
    <Grid container sx={{ height: pageHeight }}>
      {!downXs &&
        <Grid item xs={12} sm={4}
          sx={{
            backgroundImage: `url(${Onboarding})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center',
          }}
        >
        </Grid>}
      <Grid item xs={12} sm={8} display="flex" >
        <BackToHome />
        <ExittoOnboarding />
        <Grid py={5} container justifyContent="center" alignItems="center">

          {children}
        </Grid>
      </Grid>

    </Grid>
  </PageOpening>
}

export default OnboardingLayout