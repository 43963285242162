
import BizLayout from "layouts/biz/BizLayout"
import { Box, Typography, Grid, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router';
import { MedalBox } from 'components';

const info = [
    "Business bekleniyor"
]

const Ticket = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()



    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("Ticket")}</Typography>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t("Open")}
                        letterSpace={1}
                        bgColor="#e3e3e3"
                        count={0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t("Waiting")}
                        bgColor="#e3e3e3"
                        letterSpace={1}
                        count={0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t("Answered")}
                        bgColor="#e3e3e3"
                        letterSpace={1}
                        count={0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t("Closed")}
                        bgColor="#e3e3e3"
                        letterSpace={1}
                        count={0}
                    />
                </Grid>
            </Grid>
            <Divider sx={{ mt: 5}} />
            <Box display={"flex"} justifyContent={"center"}>
                <Typography variant="h7" textAlign="center" mt={{ xs: 5, lg: 3 }}>{t("There is no ticket yet!")}</Typography>
            </Box>
        </Box>

    </BizLayout >
}

export default Ticket