import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
  companyListData: null,
  companyListCount: null,
  companyListLoading: false,
  companyListError: null,
  companiesOverviewData: null,
  companiesOverviewLoading: false,
  companiesOverviewError: null,
};


export const companyListFunc = createAsyncThunk("company/companyListFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query CompanyRep_companies($pagination: Pagination) {
      companyRep_companies(pagination: $pagination) {
        count
          data {
            _id
            displayName
            totalSalesTo
            medal
            displayImageUrl
            organizationType
            country {
              _id
              name
            }
            city {
              _id
              name
            }
            companyAdmin {
              _id
              displayName
            }
            numberOfEmployee {
              _id
              name
            }
          }
      }
    }`,
    variables: {
      "pagination": {
        "page": values.page,
        "pageSize": values.pageSize
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


export const companiesOverviewFunc = createAsyncThunk("company/companiesOverviewFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query CompanyRep_dashboardOverviews {
      companyRep_dashboardOverviews {
        companiesOverview {
          bronzeCount
          silverCount
          goldCount
          vipCount
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});




const company = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(companyListFunc.pending, (state) => {
      state.companyListLoading = true;
    }
    );
    builder.addCase(companyListFunc.fulfilled, (state, action) => {
      state.companyListLoading = false;
      state.companyListData = action.payload?.companyRep_companies?.data;
      state.companyListCount = action.payload?.companyRep_companies?.count;
      state.companyListError = action.payload.message;
    }
    );
    builder.addCase(companyListFunc.rejected, (state, action) => {
      state.companyListLoading = false;
      state.companyListError = action.error.message;
    }
    );
    builder.addCase(companiesOverviewFunc.pending, (state) => {
      state.companiesOverviewLoading = true;
    }
    );
    builder.addCase(companiesOverviewFunc.fulfilled, (state, action) => {
      state.companiesOverviewLoading = false;
      state.companiesOverviewData = action.payload?.companyRep_dashboardOverviews?.companiesOverview;
      state.companiesOverviewError = action.payload.message;
    }
    );
    builder.addCase(companiesOverviewFunc.rejected, (state, action) => {
      state.companiesOverviewLoading = false;
      state.companiesOverviewError = action.error.message;
    }
    );
  }
});


export default company.reducer;