import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box, Typography } from '@mui/material';
import AuthLayout from '../../layouts/auth/AuthLayout'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack'
import { AuthHead, BasicInputPassword, BlackButton } from 'components'
import { useNavigate } from 'react-router';
import { resetPasswordFunc } from 'context/auth/auth';
import regexPattern from 'src/utils/regexPattern';



const ValidationSchema = Yup.object({
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters!')
        .required('Password is required!')
        .matches(regexPattern.atLeastOnelowerCases, "Must contain lower case!")
        .matches(regexPattern.atLeastOneupperCases, "Must contain upper case!")
        .matches(regexPattern.atLeastOnenumbers, "Must contain number!")
        .matches(regexPattern.atLeastOnepecialCharacters, "Must contain special character!"),
    verifyPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match!')
        .required('Password is required!')
})


const ResetPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { signinLoading } = useSelector(state => state.auth);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");

    const { handleSubmit, handleChange, handleBlur, errors, values, touched } = useFormik({
        initialValues: {
            verifyPassword: '', password: ''
        },
        validationSchema: ValidationSchema,
        onSubmit: () => {
            console.log('submit', values)

            dispatch(resetPasswordFunc({
                password: values.password,
                passwordToken: token
            })).then((res) => {
                console.log(res?.payload)
                if (res?.payload?.isSuccess) {
                    enqueueSnackbar('Password created successfully', { variant: 'success' })
                    navigate('/signin')
                } else if (res?.payload?.response?.data?.message === "PasswordMustBeDifferentFromPrevious") {
                    enqueueSnackbar('Password must be different from previous', { variant: 'error' })
                } else {
                    enqueueSnackbar('Password creation failed', { variant: 'error' })
                }
            }).catch((err) => {
                enqueueSnackbar('Password creation failed', { variant: 'error' })
            })
        }
    })




    return <AuthLayout>
        <Grid item xs={11} sm={9} md={6} lg={5} xl={4} justifyContent="center" alignItems="center" flexDirection={"column"}>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                <AuthHead title="Reset Password" description="You can login to the panel by creating your new password." />
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" marginTop="30px">

            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" width="100%">
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <Box flexDirection="column" alignItems="center" gap="10px" width="100%">
                        <BasicInputPassword
                            name="password"
                            type="password"
                            label="Password"
                            placeholder="Enter your password"
                            value={values?.password}
                            error={touched.password && errors.password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                        <BasicInputPassword
                            name="verifyPassword"
                            type="password"
                            label="Verify Password"
                            placeholder="Reenter your password"
                            value={values?.verifyPassword}
                            error={touched.verifyPassword && errors.verifyPassword}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />

                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%">
                            <BlackButton
                                name="Create Password"
                                isActive={values?.verifyPassword && values?.password && !errors?.verifyPassword && !errors?.password}
                                handleSubmit={handleSubmit}
                                loading={signinLoading}
                            />
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%" mt={"15px"} mb={"30px"}>
                            <Typography variant="h7" color="primary.300">
                                Do you have an account? <a href="/signin" style={{ textDecoration: 'none' }}><Typography variant="h7" color="info.main" ml={"5px"}>
                                    Sign In
                                </Typography>
                                </a>
                            </Typography>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Grid>
    </AuthLayout >
}

export default ResetPassword