import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BizLayout from "layouts/biz/BizLayout"
import {
    Box, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, CircularProgress, Pagination, InputAdornment
} from '@mui/material'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { MedalBox } from "components";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import HistoryIcon from '@mui/icons-material/History';
import { useTranslation } from 'react-i18next';
import { orderListFunc } from "context/client/finance";


const info = [
    "Bitti"
]

const validationSchema = Yup.object({
    fullName: Yup.string().required('Full Name is required'),
    // name: Yup.string().required('Name is required'),
    // surname: Yup.string().required('Surname is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const pageSize = 10;

const Finance = () => {

    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken, salesRep_registerLoading, userId } = useSelector((state) => state.auth);
    const { clientListData, clientListCount, clientsOverviewData } = useSelector((state) => state.client);
    const { packagesOverviewData } = useSelector(state => state.packages);
    

    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { orderListData, orderListLoading } = useSelector((state) => state.finance);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            fullName: '',
            amount: '',
            // name: '',
            // surname: '',
            email: '',
            password: '101010Q.q',
            role: 'b2bClient',
            accessToken: accessToken
        },
        validationSchema,
        onSubmit: (values) => {

        },
    });

    useEffect(() => {
        accessToken && dispatch(orderListFunc({ accessToken: accessToken, page: page, pageSize: pageSize, userId: userId }));
    }, [dispatch, accessToken, page]);

    console.log(orderListData)

    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("Finance")}</Typography>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={6} md={4}>
                    <MedalBox
                        medalType={t("Standard Package")}
                        letterSpace={1}
                        bgColor="#FFEFE2"
                        count={t('Usable') + " : " + (packagesOverviewData ? packagesOverviewData?.standard?.usable : 0)}
                        count2={t("Total") + " : " + (packagesOverviewData ? packagesOverviewData?.standard?.total : 0)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MedalBox
                        medalType={t("Pro Package")}
                        letterSpace={1}
                        bgColor="#F5F5F5"
                        count={t('Usable') + " : " + (packagesOverviewData ? packagesOverviewData?.pro?.usable : 0)}
                        count2={t("Total") + " : " + (packagesOverviewData ? packagesOverviewData?.pro?.total : 0)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MedalBox
                        medalType={t("Special Package")}
                        bgColor="#fff1ba"
                        letterSpace={1}
                        count={t('Usable') + " : " + (packagesOverviewData ? packagesOverviewData?.special?.usable : 0)}
                        count2={t("Total") + " : " + (packagesOverviewData ? packagesOverviewData?.special?.total : 0)}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={3} alignItems="center">
                <Grid item xs={6} >
                    <Typography variant="h5">{t("Finance List")}</Typography>
                </Grid>
                {/* <Grid item xs={6} display="flex" justifyContent={"end"}>
                    <Button onClick={null} style={{ marginRight: 20 }}
                        variant="outlined" color="primary" size="large"> <HistoryIcon color="white"
                            fontSize="medium" style={{ marginRight: 10 }} />
                        {t("History")}
                    </Button>
                </Grid> */}
            </Grid>

            <Grid container spacing={0} mt={3} sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
            }} component={Paper} >
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>{t("Package Type")}</TableCell>
                                <TableCell align="center">{t("#Internship Credit")}</TableCell>
                                <TableCell align="center">{t("Listing Price")}</TableCell>
                                <TableCell align="center">{t("Discount")}</TableCell>
                                <TableCell align="center">{t("Price")} ($)</TableCell>
                                <TableCell align="center">{t("Payment Method")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderListData ?
                                orderListData?.map((item, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}      >
                                        <TableCell component="th" scope="row">{page === 1 ? index + 1 : (page - 1) * pageSize + index + 1}</TableCell>
                                        <TableCell align="center">{item.product?.type}</TableCell>
                                        <TableCell align="center">{item.product?.amount}</TableCell>
                                        <TableCell align="center">{item.product?.listUnitPrice?.toFixed(1)}</TableCell>
                                        <TableCell align="center">{(item.product?.discount / 100).toFixed(1)}</TableCell>
                                        <TableCell align="center">{(item.product?.price / 100).toFixed(1)}</TableCell>
                                        <TableCell align="center">{item.product?.purchaseMethod}</TableCell>
                                    </TableRow>
                                )) :
                                <TableRow>
                                    <TableCell colSpan={8} align="center">{t('No data found')}</TableCell>
                                </TableRow>
                            }
                        </TableBody>

                    </Table>
                    <Box display="flex" justifyContent="center" p={2}>
                        <Pagination
                            count={Math.ceil(clientListCount / pageSize)}
                            page={page}
                            onChange={handleChange}
                            color="primary"
                        />
                    </Box>
                </TableContainer>
            </Grid>
            <Box height={40} />
        </Box>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={formik.handleSubmit} style={{ padding: 10 }}>
                <DialogTitle id="form-dialog-title">{t("Withdraw Money")}</DialogTitle>

                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    </BizLayout>
}

export default Finance