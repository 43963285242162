import { Route } from 'react-router-dom'
import Dashboard from 'pages/companyRep/Dashboard'
import Company from 'pages/companyRep/Company'
import Finance from 'pages/companyRep/Finance'
import Ticket from 'pages/companyRep/Ticket'
import Profile from 'pages/user/Profile'
import Settings from 'pages/user/Settings'
import Onboarding from 'pages/companyRep/Onboarding'
import Notepad from 'pages/user/Notepad'



export const CompanyRepDashboard = () => {

    return <>
        <Route path="/" element={<Dashboard />} />
        <Route path="/company" element={<Company />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/notepad" element={<Notepad />} />
        <Route path="*" element={<Dashboard />} />
    </>
}


export const CompanyRepOnboarding = () => {
    return <>
        <Route path="/" element={<Onboarding />} />
        <Route path="*" element={<Onboarding />} />
    </>
}