
import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box, Checkbox, Typography, FormControlLabel, Link } from '@mui/material';
import AuthLayout from 'layouts/auth/AuthLayout'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack'
import { LoginSocialGoogle } from 'reactjs-social-login'
import { AuthHead, AuthSeperator, BasicInput, BasicInputPassword, BlackButton, GoogleButton, LinkedinButton, WhiteButton, TermsDialog, PrivacyDialog } from 'components'
import { useNavigate } from 'react-router-dom';

import { signupFunc } from 'context/auth/auth';
import regexPattern from 'src/utils/regexPattern';


const ValidationSchema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address!')
        .required('Email is required!'),
    password: Yup.string()
        .matches(regexPattern.atLeastOnelowerCases, "Must contain lower case!")
        .matches(regexPattern.atLeastOneupperCases, "Must contain upper case!")
        .matches(regexPattern.atLeastOnenumbers, "Must contain number!")
        .matches(regexPattern.atLeastOnepecialCharacters, "Must contain special character!")
        .min(8, "Password must be at least 8 characters!")
        .required('Password is required!'),
    accept: Yup.bool()
        .oneOf([true], 'Accept Terms & Conditions is required'),
})


const Signup = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { signupLoading } = useSelector(state => state.auth);
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)
    const [userRole, setUserRole] = useState('intern')
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (openDialog) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openDialog]);

    useEffect(() => {
        if (openDialog2) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openDialog2]);


    const handleClickOpen = (type) => () => {
        type === "Terms" && setOpenDialog(true);
        type === "Privacy" && setOpenDialog2(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setOpenDialog2(false);
    };


    const { handleSubmit, handleChange, handleBlur, errors, values, touched } = useFormik({
        initialValues: {
            email: '', password: '', accept: false, role: userRole, referralCode: ''
        },
        validationSchema: ValidationSchema,
        onSubmit: () => dispatch(signupFunc(values)).then((res) => {
            console.log(res, 'resssss');
            if (res.payload?.isSuccess) {
                enqueueSnackbar('Signup Successfully', { variant: 'success' })
                setTimeout(() => {
                    navigate('/check-your-email', { state: { email: values.email } })
                }, 500)
            } else {
                enqueueSnackbar(res.payload?.response?.data?.errors?.[0]?.msg ? res.payload?.response?.data?.errors?.[0]?.msg : "Signup Failed", { variant: 'error' })
            }
        }).catch((err) => {
            console.log(err, 'err');
            enqueueSnackbar('Signup Failed', { variant: 'error' })
        })
    })

    // useEffect(() => {

    //     if (profile?.access_token) {
    //         console.log('provider', provider)
    //         console.log('profile', profile.access_token)
    //         // dispatch(signinWithGoogleFunc(profile)).then((res) => {
    //         //     console.log(res, 'res');
    //         // }).catch((err) => {
    //         //     console.log(err, 'err');
    //         // })
    //     }
    // }, [provider, profile, dispatch])

    const CLIENTID = "308592592499-a2a5d1ctvs3rjuoeber0q0ghssmfr0m1.apps.googleusercontent.com"

    return <AuthLayout>
        <Grid item xs={11} sm={9} md={6} lg={5} xl={4} justifyContent="center" alignItems="center" flexDirection={"column"}>


            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                <AuthHead title="Sign Up Now" description={`Let’s sign up as an ${userRole === "intern" ? "Intern" : "Company"}!`} />
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center" gap="10px" width="100%">
                {userRole === "intern" ?
                    <>
                        <BlackButton name="Intern" handleSubmit={() => setUserRole('intern')} />
                        <WhiteButton name="Company" handleSubmit={() => setUserRole('company')} />
                    </>
                    :
                    <>
                        <WhiteButton name="Intern" handleSubmit={() => setUserRole('intern')} />
                        <BlackButton name="Company" handleSubmit={() => setUserRole('company')} />
                    </>
                }
            </Box>


            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" marginTop="30px">
                <Box flexDirection={"column"} display="flex" gap="10px" width="100%">
                    <LinkedinButton />
                    <LoginSocialGoogle
                        // typeResponse='idToken'
                        typeResponse='accessToken'
                        client_id={CLIENTID}
                        onResolve={({ provider, data }) => {
                            setProvider(provider)
                            setProfile(data)
                        }}
                        onReject={(err) => {
                            console.log(err)
                        }}
                    >
                        <GoogleButton />
                    </LoginSocialGoogle>
                </Box>
                <AuthSeperator type="sign up" />

            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" width="100%">
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>

                    <Box flexDirection="column" alignItems="center" gap="10px" width="100%">

                        <BasicInput
                            name="email"
                            type="email"
                            label="Email*"
                            placeholder="Enter your email address"
                            value={values?.email}
                            error={touched.email && errors.email}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />

                        <BasicInputPassword
                            name="password"
                            type="password"
                            label="Password*"
                            placeholder="Enter a strong password"
                            value={values?.password}
                            error={touched.password && errors.password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />

                        <BasicInput
                            name="referralCode"
                            type="text"
                            label="Referral Code"
                            placeholder="Enter your referral code (optional)"
                            value={values?.referralCode}
                            error={touched.referralCode && errors.referralCode}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />


                        <Box display="flex" justifyContent="space-between" alignItems={'center'}  width="100%"  mb={1}>
                            <FormControlLabel
                                control={<Checkbox
                                    color="primary"
                                    checked={values?.accept}
                                    onChange={handleChange}
                                    name="accept"
                                />}
                                label={<Typography variant="h8" color="primary.main">
                                    I’ve read and accept the <Link component="span" onClick={handleClickOpen("Terms")} color="info.main" variant="h8">
                                        Terms and Conditions
                                    </Link> and <Link component="span" onClick={handleClickOpen("Privacy")} color="info.main" variant="h8">
                                        Privacy Policy</Link>  of InternIF.
                                </Typography>}
                            />
                        </Box>

                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%">
                            <BlackButton
                                name="Sign Up"
                                isActive={values?.email && values?.password && !errors?.email && !errors?.password && values?.accept}
                                handleSubmit={handleSubmit}
                                loading={signupLoading}
                            />
                        </Box>


                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%" mt={"15px"} mb={"30px"}>

                            Already have an account? <a href="/" style={{ textDecoration: 'none' }}> <Typography variant="h7" color="info.main" ml={"5px"}>
                                Sign In
                            </Typography>
                            </a>
                        </Box>



                    </Box>

                </form>
            </Box>

        </Grid >

        <TermsDialog
            openDialog={openDialog}
            handleClose={handleClose}
            descriptionElementRef={descriptionElementRef}
        />
        <PrivacyDialog
            openDialog2={openDialog2}
            handleClose={handleClose}
            descriptionElementRef={descriptionElementRef}
        />
    </AuthLayout >
}

export default Signup