import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PaymentSuccessAnimation from "assets/lottie/payment_success.json";
import { Grid, Card } from '@mui/material';
import { Typography, Button } from '@mui/material';
import Lottie from 'react-lottie';
import BizLayout from "layouts/biz/BizLayout"
import { AnimatedTitle } from 'components/Motions/AnimatedTitle';
import { user_updateOrderAfterStripeCheckoutFormPaymentFunc } from "context/common/packages";
import { useNavigate } from 'react-router-dom';



const PaymentSuccess = () => {


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const urlSearchParams = new URLSearchParams(window.location.search);
    const orderId = urlSearchParams.get("orderId");
    const { accessToken } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(user_updateOrderAfterStripeCheckoutFormPaymentFunc({ orderId, accessToken }))
    }, [orderId, accessToken, dispatch])




    return <BizLayout >
        <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', gap: { xs: 2, sm: 0 } }}>
            <Grid item xs={12} >
                <AnimatedTitle title="Payment Result" />
            </Grid>
            <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', py: 5, px:10, mb: 5 }}>

                <Typography variant="h6" textAlign={"center"} mb={5}>Payment is completed successfully.</Typography>
                <Lottie
                    options={{
                        loop: false,
                        autoplay: true,
                        animationData: PaymentSuccessAnimation,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                        }
                    }}
                    height={200}
                    width={200}
                />

<Button variant="contained" color="primary" onClick={() => navigate("/finance")}>Go to Finance</Button>

            </Card>


        </Grid>
    </BizLayout>

}


export default PaymentSuccess