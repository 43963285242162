import { Route } from 'react-router-dom'
import Dashboard from 'pages/internRep/Dashboard'
import Intern from 'pages/internRep/Intern'
import Finance from 'pages/internRep/Finance'
import Ticket from 'pages/internRep/Ticket'
import Profile from 'pages/user/Profile'
import Settings from 'pages/user/Settings'
import Onboarding from 'pages/internRep/Onboarding'
import Notepad from 'pages/user/Notepad'
import HighSchoolIntern from 'pages/internRep/HighSchoolIntern'
import Affiliate from 'pages/internRep/Affiliate'


export const InternRepDashboard = () => {

    return <>
        <Route path="/" element={<Dashboard />} />
        <Route path="/intern" element={<Intern />} />
        {/* <Route path="/finance" element={<Finance />} /> */}
        <Route path="/affiliate" element={<Affiliate />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/notepad" element={<Notepad />} />
        <Route path="/high-school-intern" element={<HighSchoolIntern />} />
        <Route path="*" element={<Dashboard />} />
    </>
}


export const InternRepOnboarding = () => {
    return <>
        <Route path="/" element={<Onboarding />} />
        <Route path="*" element={<Onboarding />} />
    </>
}