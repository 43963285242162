import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BizLayout from "layouts/biz/BizLayout"
import {
    Box, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, CircularProgress, Pagination, InputAdornment, TablePagination
} from '@mui/material'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Label from 'src/components/label/label';
import { useSnackbar } from 'notistack';
import { salesRep_registerFunc } from "context/auth/auth";
import { MedalBox } from "components";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import HistoryIcon from '@mui/icons-material/History';
import { useTranslation } from 'react-i18next';
import { user_ordersByProductCodeFunc } from "context/common/packages";
import moment from "moment";
import { internListFunc } from "context/internRep/intern";

const Affiliate = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { userBillingInfoData: user_payment, user_ordersByProductCodeData: affiliate_history, user_ordersByProductCodeCount: affiliate_count } = useSelector((state) => state.packages);
    const { accessToken } = useSelector((state) => state.auth);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }


    useEffect(() => {
        dispatch(user_ordersByProductCodeFunc({ accessToken: accessToken, page: page, pageSize: pageSize }))
    }, [page, pageSize])

    console.log(affiliate_history, " affiliate_history")

    return <BizLayout  >
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("Affiliate")}</Typography>
            <Grid container spacing={2} mt={1} mb={5}>
                <Grid item xs={12}>
                    <Box display="flex" bgcolor={"#f5f5f5"} p={2} borderRadius={2} alignItems="center" gap={1}>
                        <Typography variant="h5"  >{t("Promotion Code : ")} </Typography><Typography variant="h5" color={"secondary.main"} >{user_payment?.productCode?.name ?? "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} mt={5}>
                    <Typography variant="h5" mb={2} >{t("Affiliate History")} </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("#")}</TableCell>
                                    <TableCell>{t("Full Name")}</TableCell>
                                    <TableCell>{t("User Type")}</TableCell>
                                    <TableCell>{t("Product")}</TableCell>
                                    <TableCell>{t("Product Price")}</TableCell>
                                    <TableCell>{t("Currency")}</TableCell>
                                    <TableCell>{t("Date")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {affiliate_history?.length > 0 ? affiliate_history?.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{pageSize * (page - 1) + index + 1}</TableCell>
                                        <TableCell>{row?.user?.displayName}</TableCell>
                                        <TableCell>{row?.user?.role === "intern" ? t("Intern") : row?.user?.role === "highSchoolIntern" ? t("Intern (High School)") : t(".")}</TableCell>
                                        <TableCell>{row?.product?.name}{t(" Package")}</TableCell>
                                        <TableCell>{row?.price?.netTotal?.toFixed(2)}</TableCell>
                                        <TableCell>{row?.price?.currency}</TableCell>
                                        <TableCell>{moment(row?.createdAt).format("YYYY-MM-DD")}</TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={6} align="center">{t("No data found.")}</TableCell>
                                </TableRow>}
                            </TableBody>


                        </Table>
                        <Box display="flex" justifyContent="center" p={2}>
                            <Pagination
                                count={Math.ceil(affiliate_count / pageSize)}
                                page={page}
                                onChange={handleChangePage}
                                color="primary"
                                style={{ marginLeft: 10 }}
                            />
                        </Box>
                    </TableContainer>

                </Grid>

            </Grid>
        </Box>


    </BizLayout>
}

export default Affiliate